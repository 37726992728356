<template>
    <div>
        <div class="container">
            <div>
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <div class="content-login">
                            <div class="login-title">
                                <h4>Te damos la bienvenida al Portal del Ciclo de Aprendizaje</h4>
                            </div>
                            <div class="card-login">
                                <!--<div class="card-header">
                                    <h1 class="card-title">Te damos la bienvenida al Portal del Ciclo de Aprendizaje</h1>
                                </div>-->
                                <!--<div class="card-body"  style="width: 100%;">-->
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="left-container">
                                            <div class="imagen-login"></div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-login">
                                                <div class="form-group">
                                                    <label>Usuario/a</label>
                                                    <input type="text" class="form-control" v-model="loginData.username" placeholder="Ingrese su RUT sin puntos y con guión" required>
                                                    <small class="form-text text-muted"></small>
                                                    <div class="invalid-error" v-show="loginError" role="alert">
                                                        Usuario incorrecto
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Contraseña</label>
                                                    <input type="password" class="form-control" v-model="loginData.password" placeholder="Contraseña" required  @keyup.enter="loginFuncionario">
                                                    <small class="form-text text-muted"></small>
                                                    <div class="invalid-error" v-show="loginError" role="alert">
                                                        Contraseña incorrecta
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row justify-content-center" style="text-align: center;">
                                                    <div class="col-md-6 justify-content-right">
                                                        <button type="button" class="btn btn-primary" @click="loginFuncionario"><font-awesome-icon :icon="['fas', 'sign-in-alt']" /> Ingresar</button>
                                                    </div>
                                                    <div class="col-md-6 justify-content-left">
                                                        <a @click="recuperarAlert()" class="btn btn-md" style="cursor: pointer; margin:auto;">Recuperar Contraseña</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <!--</div>-->
                            </div>
                            <br>
                            <br>
                            <!--<div class="row col-md-12" style="text-align: center;">
                                <p style="margin:auto;">Para consultas, contacte a <a style="text-decoration:none;" href="mailto:estudiosestadisticas@minciencia.gob.cl">estudiosestadisticas@minciencia.gob.cl</a></p>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Footer/>-->
    </div>
</template>

<script>
import axios from 'axios'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
// import Footer from '@/components/Footer.vue'

export default {
    name: 'Login',
    components: {
        // Footer
    },
    data () {
        return {
            loginError: false,
            loginData: { username: null, password: null },
            resetData: { username: null }
        }
    },
    mounted () {
        localStorage.removeItem('jwt.access')
        localStorage.removeItem('jwt.refresh')
    },

    methods: {
        loginFuncionario (e) {
            e.preventDefault()
            var url = '/api/token/'
            this.loginError = false
            axios({
                method: 'post',
                url: url,
                data: {
                    username: this.loginData.username,
                    password: this.loginData.password
                }
            })
                .then((response) => {
                    localStorage.setItem('jwt.access', response.data.access)
                    localStorage.setItem('jwt.refresh', response.data.refresh)
                    this.$router.push('/')
                    location.reload()
                })
                .catch((response) => {
                    this.loginError = true
                    localStorage.removeItem('jwt.access')
                    localStorage.removeItem('jwt.refresh')
                })
        },
        async recuperarAlert () {
            const customer = await this.$swal({
                title: `<div class="swt1">${icon(faUnlockAlt).html.join('')}   Recuperar Contraseña</div>`,
                html: '<div>Para recuperar su contraseña ingrese su RUN, sin puntos y con guión <i>(ejemplo: 1111111-4)</i>.</div><br>',
                input: 'text',
                inputPlaceholder: 'Ingrese su RUN sin puntos y con guión',
                footer: '<div><p>Si tiene problemas de acceso contacte a</p><a href="mailto:estudiosestadisticas@minciencia.gob.cl">estudiosestadisticas@minciencia.gob.cl</a></div>',
                confirmButtonText: 'Recuperar Contraseña',
                confirmButtonColor: '#122999',
                showCloseButton: true
            })
            if (customer.value) {
                this.recuperar(customer.value.toLowerCase())
            }
        },
        recuperar (username) {
            axios({
                method: 'post',
                url: '/usuarios/api/reset_usuario/',
                data: {
                    username: username
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Correo de recuperación enviado correctamente'
                        })
                        this.$swal({
                            title: 'Se envió una clave temporal a su correo',
                            text: 'En caso de no recibirlo, revisa la bandeja de no deseados, o comunicate con nosotros: estudiosestadisticas@minciencia.gob.cl',
                            icon: 'success',
                            showCloseButton: true
                        })
                    } else if (response.status === 204) {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Usuario incorrecto, intente nuevamente'
                        })
                    }
                })
                .catch((response) => {
                    console.log(response)
                })
        }

    }
}
</script>

<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Tipos de Indicador" nombreAnterior="Panel de Administración" urlAnterior="/panel_administracion"></Breadcrumb>
        <b-modal id="modal-create-tipo" size="lg" title="Tipo de Indicador">
            <div class="row">
                <div class="col-md-6">
                    <label>
                        Nombre*
                        <a href="#" v-b-tooltip.hover title="Nombre que recibe el Tipo de Indicador/Tipo de Teoría de Cambio"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                    <input type="text" name="" class="form-control" v-model="tipo.nombre">
                </div>
                <div class="col-md-6">
                    <label>
                        Abreviatura*
                        <a href="#" v-b-tooltip.hover title="Abreviatura que recibe el Tipo de Indicador/Tipo de Teoría de Cambio"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                    <input type="text" name="" class="form-control" v-model="tipo.abreviatura">
                </div>
                <div class="col-md-6">
                    <label>
                        Orden de Aparición*
                        <a href="#" v-b-tooltip.hover title="Orden en el que aparece en la de TdC"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                    <input type="number" min="1" name="" class="form-control" v-model="tipo.orden">
                </div>
                <div class="col-md-6" style="margin-top:35px;">
                    <input class="form-check-input" type="checkbox" v-model="tipo.tiene_indicador" />
                    <label class="form-check-label">
                        ¿Se asociarán indicadores?
                        <a href="#" v-b-tooltip.hover title="En la TdC de este tipo, ¿se le pueden asociar indicadores?"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <b-button size="md" variant="outline-secondary" @click="cancel()">
                    Cancelar
                </b-button>
                <a v-if="!editar" @click="createTipoIndicador();" class="btn btn-primary">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
                <a v-if="editar" @click="updateTipoIndicador(tipo);" class="btn btn-primary">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
            </template>
        </b-modal>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card--button">
                            <b-button v-b-modal.modal-create-tipo class="card--button__btn" @click="openCreate()">
                                <font-awesome-icon :icon="['fas', 'plus']" />
                                <span class="card--button__text">
                                    Crear Tipo de Indicador
                                </span>
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <!-- <div class="section__container">
                                    <div class="card card--section">
                                        <div class="card-header"> -->
                                        <div class="table-responsive">
                                            <table class="table table-stripped table-hover table-bordered" id="tabla-tipos" v-if="tipoIndicadores.length>0">
                                                <thead>
                                                    <tr>
                                                        <td>Nombre</td>
                                                        <td>Abreviatura</td>
                                                        <td>Orden de Aparición</td>
                                                        <td>¿Se asociarán indicadores?</td>
                                                        <td>Acción</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="tipo in tipoIndicadores" v-bind:key="tipo.id">
                                                        <td>{{tipo.nombre}}</td>
                                                        <td>{{tipo.abreviatura}}</td>
                                                        <td>{{tipo.orden}}</td>
                                                        <td>{{tipo.tiene_indicador|validationFormat}}</td>
                                                        <td>
                                                        <div class="btns mt-0">
                                                            <b-button v-b-modal.modal-create-tipo variant="primary" class="btn btn--white btn-sm" @click="openUpdate(tipo)">
                                                                <font-awesome-icon :icon="['fas', 'edit']" /> Editar
                                                            </b-button>
                                                            <a @click="deleteAlert(tipo)" role="button" class="btn btn-danger btn-sm"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                            <!-- <router-link :to="'/editar_usuario/' + tipo.id" class="btn btn--white"><font-awesome-icon :icon="['fas', 'edit']" /> Editar</router-link> -->
                                                        </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                              <b-pagination
                                              v-model="currentPage"
                                              :total-rows="rows"
                                              :per-page="perPage"
                                              aria-controls="tabla-tipos"
                                              ></b-pagination>
                                        <!-- </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'
// import router from '../router/index.js'

const TipoIndicador = resource('/indicadores/api/crear_tipo_indicador', axios)
export default {
    name: 'CrearTipoIndicador',
    components: {
        Breadcrumb
    },
    data () {
        return {
            tipoIndicadores: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            tipo: {},
            editar: false,
            show: true
        }
    },
    methods: {
        getData () {
            TipoIndicador.query({ page: this.currentPage, per_page: 10 }).then((res) => {
                this.tipoIndicadores = res.data.items
                this.perPage = res.data.paginate.per_page
                this.rows = res.data.paginate.count
                this.show = false
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
        },
        createTipoIndicador () {
            this.show = true
            if (this.tipo.tiene_indicador === undefined) this.tipo.tiene_indicador = false
            axios({
                method: 'post',
                url: '/indicadores/api/crear_tipo_indicador/',
                data: this.tipo
            })
                .then((response) => {
                    this.show = false
                    this.$root.$emit('bv::hide::modal', 'modal-create-tipo')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Tipo Indicador creado exitosamente'
                    })
                    this.getData()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el Tipo Indicador'
                    })
                })
        },
        updateTipoIndicador (tipo) {
            this.show = true
            axios({
                method: 'put',
                url: '/indicadores/api/crear_tipo_indicador/' + tipo.id + '/',
                data: tipo
            })
                .then((response) => {
                    this.show = false
                    this.$root.$emit('bv::hide::modal', 'modal-create-tipo')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Tipo Indicador actualizado exitosamente'
                    })
                    this.getData()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el Tipo Indicador'
                    })
                })
        },
        openCreate () {
            this.editar = false
            this.tipo = {}
        },
        openUpdate (tipo) {
            this.editar = true
            this.tipo = tipo
        },
        deleteAlert (tipo) {
            this.$swal({
                title: 'Vas a eliminar el Tipo de Indicador',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteTipo(tipo)
                }
            })
        },
        deleteTipo (tipo) {
            axios({
                method: 'delete',
                url: '/indicadores/api/crear_tipo_indicador/' + tipo.id + '/'
            })
                .then((response) => {
                    this.getData()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Tipo de Indicador'
                    })
                    console.log(response)
                })
        }
    },
    mounted () {
        this.$watch('currentPage', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
    }
}
</script>

<template>
    <div>
        <div class="container-fluid">
            <b-overlay :show="show" no-wrap>
            </b-overlay>
            <div class="row">
            <div class="col-md-8">
                <Breadcrumb nombreActual="Gestor de Ficha" nombreAnterior="Fichas" urlAnterior="/listar_fichas"></Breadcrumb>
            </div>
            <div class="col-md-4">
                <div class="mt-4 float-right" v-if="ficha">
                    <a class="btn btn-secondary mr-2" @click="$bvModal.show('modal-duplicar');selectedYear=null;"><font-awesome-icon :icon="['fas', 'copy']" /> Duplicar</a>
                    <a class="btn btn-danger" @click="deleteAlert(ficha)" v-if="ficha.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'trash-alt']" /> Eliminar</a>
                </div>
            </div>
            <b-modal id="modal-duplicar" hide-footer no-close-on-backdrop>
                <template #modal-title>
                    ¿Deseas duplicar esta Ficha?
                </template>
                <div class="d-block text-center mb-3">
                    <h3>Ingresa el año para la nueva Ficha</h3>
                    <v-select :options="years" v-model="selectedYear" label="year" placeholder="Seleccionar"></v-select>
                </div>
                <div class="btns-options">
                    <div class="row col-md-12">
                            <b-button class="btn btn-secondary col-md-11" :disabled="!selectedYear" block @click="copyFicha(selectedYear, 'duplicar')">Duplicar ficha con las respuestas asociada</b-button><a href="#" v-b-tooltip.hover title="Duplicar con las respuestas que hayan respondido las Agencias" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </div>
                    <br>
                    <div class="row col-md-12">
                        <b-button class="btn btn-primary col-md-11" :disabled="!selectedYear" block @click="copyFicha(selectedYear, 'copiar')">Duplicar ficha sin las respuestas</b-button><a href="#" v-b-tooltip.hover title="Responder con las respuestas vacías" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </div>
                    <br>
                    <div class="row col-md-12">
                        <b-button class="btn btn--lightgrey" block @click="$bvModal.hide('modal-duplicar')">Cancelar</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
        </div>
        <section class="ficha ficha--crear" v-if="!routeParams">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <div class="section__container">
                            <div class="card card--section card--crear">
                                <div class="card-body">
                                    <h2 class="card-title">Ingresar datos de la ficha</h2>
                                    <div class="custom-explain">
                                        *Todos los campos son obligatorios.
                                    </div>
                                    <div class="form-group">
                                        <label class="text-dark">Nombre de la ficha:<!--<a href="#" v-b-tooltip.hover title="Nombre correspondiente a la ficha"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>--></label>
                                        <input type="text" name="" class="form-control" v-model="formFicha.nombre">
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="text-dark">Tipo de Ficha (Diseño o Evaluación)<!--<a href="#" v-b-tooltip.hover title="Tipo al que corresponde la ficha"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>--></label>
                                            <select v-model="formFicha.tipo_ficha" class="form-control">
                                                <option v-for="tipo in tiposficha" v-bind:key="tipo" :value="tipo">
                                                    {{tipo}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="text-dark">Año de la ficha<!--<a href="#" v-b-tooltip.hover title="Año de evaluación de la ficha"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>--></label>
                                            <select v-model="formFicha.anio" class="form-control">
                                                <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="btns btns--right" id="margintop">
                                        <router-link to="/listar_fichas" class="btn btn--lightgrey2">Cancelar</router-link>
                                        <a @click="createFicha" class="btn btn-primary">Guardar y Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="ficha ficha--crear-secciones" v-if="routeParams && ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body">
                                <div class="section__container">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h2 class="card-title">Ficha de {{ficha.tipo_ficha}}: {{ ficha.nombre }} - {{ficha.anio}}</h2>
                                        </div>
                                        <div class="col-md-4">
                                            <a @click="editar=true;" class="btn btn-secondary float-right" title="Editar información de la Ficha" v-show="ficha.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'edit']" /></a>
                                        </div>
                                    </div>
                                    <!--EDIT-->
                                    <div class="form-group" v-if="editar">
                                        <label>Nombre*</label>
                                        <input type="text" name="" class="form-control" v-model="ficha.nombre">
                                        <label>Tipo de Ficha*</label>
                                            <select  v-model="ficha.tipo_ficha" class="form-control">
                                                <option v-for="tipo in tiposficha" v-bind:key="tipo" :value="tipo">
                                                    {{tipo}}
                                                </option>
                                        </select>
<!--                                         <label>Año de la ficha*</label>
                                        <select v-model="ficha.anio" class="form-control">
                                            <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                                        </select> -->
                                    </div>
                                    <div class="btns btns--right">
                                        <a v-if="editar" @click="editar=false;" class="btn btn--lightgrey"><font-awesome-icon :icon="['fas', 'times']" /> Cancelar</a>
                                        <a v-if="editar" @click="updateFicha(ficha)" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                    </div>
                                    <!--FIN EDIT-->
                                </div>
                            </div>
                        </div>
                                <div class="section__container">
                                    <div v-for="seccion in ficha.secciones" v-bind:key="seccion.id">
                                        <b-card no-body class="mb-1" :class="{ active: selectedSeccion===seccion.id }">
                                                <b-card-body>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div>
<!--                                                                 <div class="dropdown float-right">
                                                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                                                                    </a>
                                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" @mouseover="onOver" @mouseleave="onLeave">
                                                                        <a class="dropdown-item" href="#">Eliminar sección</a>
                                                                        <a class="dropdown-item" href="#">Archivar</a>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                            <h2 class="card-title">Sección {{seccion.numero}}: {{seccion.nombre}}
                                                            <a @click="deleteSeccionAlert(seccion)" role="button" v-show="ficha.estado=='Por Publicar'" class="btn btn-danger btn-sm float-right"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                        </h2>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <!-- Título sección -->
                                                        <div class="form-group col-md-6">
                                                            <label>Número de la sección*</label>
                                                            <input type="number" name="" class="form-control" v-model="seccion.numero" v-on:keyup="selectedSeccion=seccion.id;" :disabled="ficha.estado==='Publicada'">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Título de la sección*</label>
                                                            <input type="text" name="" class="form-control" v-model="seccion.nombre" v-on:keyup="selectedSeccion=seccion.id;" :disabled="ficha.estado==='Publicada'">
                                                        </div>
                                                    </div>
                                                        <div class="btns btns--right">
                                                            <a @click="updateSeccion(seccion);createSubSeccion(seccion)" class="btn btn-secondary" v-show="ficha.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'indent']" /> Guardar y crear nueva subsección</a>
                                                            <a @click="updateSeccion(seccion)" class="btn btn-primary" v-show="ficha.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                                        </div>
                                                        <div class="accordion" role="tablist" v-for="subseccion in seccion.subsecciones" v-bind:key="subseccion.id">
                                                            {{ funcion_stpda(subseccion) }}
                                                            <b-card no-body class="card--subsection mb-1">
                                                                <b-card-header header-tag="header" class="p-0" role="tab">
                                                                    <b-button block v-b-toggle="'subaccordion-' + subseccion.id" variant="info" @click="selectedSubseccion=subseccion;selectedSeccion=seccion.id;">Sub-Sección {{subseccion.numero}}: <em>{{subseccion.nombre}}</em>
                                                                        <span class="float-right" v-if="selectedSubseccion!==subseccion">
                                                                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                                                                        </span>
                                                                        <span class="float-right" v-if="selectedSubseccion===subseccion">
                                                                            <font-awesome-icon :icon="['fas', 'chevron-down']" />
                                                                        </span>
                                                                    </b-button>
                                                                </b-card-header>
                                                                <b-collapse :id="'subaccordion-' + subseccion.id" accordion="my-subaccordion" role="tabpanel">
                                                                    <b-card-body>
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <a @click="deleteSubSeccionAlert(subseccion)" role="button" v-show="ficha.estado=='Por Publicar'" class="btn btn-danger btn-sm float-right"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="form-group col-md-6">
                                                                                <label>Número de la subsección*</label>
                                                                                <input type="number" name="" class="form-control"  v-model="subseccion.numero" :disabled="ficha.estado==='Publicada'">
                                                                            </div>
                                                                            <div class="form-group col-md-6">
                                                                                <label>Título de la subsección*</label>
                                                                                <input type="text" name="" class="form-control"  v-model="subseccion.nombre" :disabled="ficha.estado==='Publicada'">
                                                                            </div>
                                                                        </div>
                                                                        <br>
                                                                        <div class="row">
                                                                            <div class="form-group col-md-6">
                                                                                <label>Descripción de la Subsección</label>
                                                                                <ckeditor
                                                                                    :editor="editor"
                                                                                    v-model="subseccion.descripcion"
                                                                                    :config="editorConfig"
                                                                                    :disabled="ficha.estado==='Publicada'"
                                                                                  />
                                                                            </div>
                                                                            <div class="form-group col-md-6">
                                                                                <label>Notas adicionales</label>
                                                                                <ckeditor
                                                                                    :editor="editor"
                                                                                    v-model="subseccion.notas"
                                                                                    :config="editorConfig"
                                                                                    :disabled="ficha.estado==='Publicada'"
                                                                                  />
                                                                            </div>
                                                                        </div>
                                                                        <br>
                                                                        <div class="row col-md-12">
                                                                            <h5>Elemento de respuesta</h5>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="row">
                                                                                    <div v-if="subseccion.elementos.length > 0" class="col-md-12">
                                                                                        <div v-for="elemento in subseccion.elementos" v-bind:key="elemento.id" class="elemento-subseccion__item">
                                                                                            <span v-if="elemento.elemento.type !== 'table'">
                                                                                                <FormulateInput :type="elemento.elemento.type" :validation="elemento.elemento.validation" :options="elemento.elemento.options" :disabled="ficha.estado==='Publicada'"/>
                                                                                            </span>
                                                                                            <span v-if="elemento.elemento.type === 'table'">
                                                                                                <ckeditor
                                                                                                    :editor="editor"
                                                                                                    v-model="elemento.elemento.content"
                                                                                                    :config="editorTableConfig"
                                                                                                    v-on:blur="updateElemento(elemento)" :disabled="ficha.estado==='Publicada'"
                                                                                                />
                                                                                                <!-- {{subsecci}} -->
                                                                                            </span>
                                                                                            <!-- <a @click="deleteElement(subseccion,index)" class="btn btn-secondary"><font-awesome-icon :icon="['fas', 'trash-alt']" /> Eliminar</a> -->
                                                                                            <a @click="deleteElementAlert(subseccion, elemento)" role="button" class="btn-icon-remove" v-show="ficha.estado=='Por Publicar'">Eliminar</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="btns btns--right">
                                                                            <!-- <small class="autosave-mje"><font-awesome-icon :icon="['fas', 'spinner']" /> Autoguardando</small>
                                                                            <small class="autosave-mje"><font-awesome-icon :icon="['fas', 'check-double']" /> Guardado hace 2 minutos</small> -->
                                                                            <a @click="updateSubSeccion(subseccion);createSubSeccion(seccion)" class="btn btn-secondary" v-show="ficha.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'indent']" /> Guardar y crear nueva Subsección</a>
                                                                            <a @click="updateSubSeccion(subseccion);" class="btn btn-primary" v-show="ficha.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                </b-card-body>
                                            <!-- </b-collapse> -->
                                        </b-card>
                                    </div>
                                </div>
                                <div class="card card--button">
                                    <a @click="createSeccion()" class="card--button__btn card--button__btn--lightgrey" v-show="ficha.estado==='Por Publicar'">
                                        <font-awesome-icon :icon="['fas', 'plus']" />
                                        <span class="card--button__text">Crear Nueva sección</span>
                                    </a>
                                </div>
                    </div>
                    <div class="col-md-4">
                            <div class="section-elements" v-bind:class="{sectionElementsOriginal: scrollPosition < 170, sectionElementsFixed: scrollPosition > 170}">
                                <div class="card">
                                    <div class="card-body">
                                        <h2 class="card-title">Información adicional</h2>
                                        <router-link :to="'/preview_ficha/' + ficha.id" class="btn btn--lightgrey btn-sm float-right" target="_blank"><font-awesome-icon :icon="['fas', 'eye']" /> Vista Previa</router-link>
                                        <!-- <a class="btn btn-warning"><font-awesome-icon :icon="['fas', 'ban']" /> Inhabilitar</a> -->
                                        <p class="mb-3">Estado de la ficha: <span class="badge bg-success">{{ficha.estado}}</span></p>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="ficha.tiene_indicadores" @click="ficha.tiene_indicadores=!ficha.tiene_indicadores;updateFicha(ficha);" />
                                            <label class="form-check-label">
                                            ¿Se asociarán indicadores?
                                                <a href="#" v-b-tooltip.hover title="Permite la creación de indicadores asociados a la ficha"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="ficha.tiene_tdc" @click="ficha.tiene_tdc=!ficha.tiene_tdc;updateFicha(ficha);" />
                                            <label class="form-check-label">
                                            ¿Posee Teoría de Cambio?
                                                <a href="#" v-b-tooltip.hover title="Permite la creación de TdC asociado a la ficha"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="ficha.tiene_archivos" @click="ficha.tiene_archivos=!ficha.tiene_archivos;updateFicha(ficha);" />
                                            <label class="form-check-label">
                                            ¿Permite la carga de documentos de respaldo?
                                                <a href="#" v-b-tooltip.hover title="Permite que se carguen documentos externos"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                            </label>
                                        </div>
                                        <div class="mb-3" v-if="ficha.tiene_archivos">
                                            <label>Descripción general de los archivos</label>
                                            <textarea v-model="ficha.descripcion_archivos" class="form-control"></textarea>
                                        </div>
                                        <div class="mb-3">
                                            <label>Instrumentos Asociados </label>
                                                <a href="#" v-b-tooltip.hover title="Instrumentos que rellenarán la ficha"> <font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                            <v-select multiple :options="instrumentos" v-model="ficha.instrumentos" label="nombre" :reduce="nombre => nombre.id">
                                                <template slot="option" slot-scope="option">
                                                   {{ option.nombre }} - <i>{{option.subdireccion__nombre}}</i> - <strong>{{option.agencia__nombre}}</strong>
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                   {{ option.nombre }}
                                                </template>
                                            </v-select>
                                        </div>
                                        <div class="btns btns--right">
                                                <a class="btn btn-secondary" @click="updateFicha(ficha);" v-if="ficha.estado=='Por Publicar'"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                                <a class="btn btn-primary" @click="ficha.estado='Publicada';updateFicha(ficha);" v-if="ficha.estado=='Por Publicar'"><font-awesome-icon :icon="['fas', 'check-double']" /> Guardar y Publicar Ficha</a><a v-if="ficha.estado=='Por Publicar'" href="#" v-b-tooltip.hover title="Al publicar la ficha, quedará visible para las agencias"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                                <a class="btn btn--lightgrey" @click="ficha.estado='Por Publicar';updateFicha(ficha);" v-if="ficha.estado=='Publicada'"><font-awesome-icon :icon="['fas', 'file']" /> Marcar como Borrador
                                                </a><a v-if="ficha.estado=='Publicada'" href="#" v-b-tooltip.hover title="Si se marca como Borrador las agencias dejarán de ver la Ficha"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                                <a class="btn btn-primary" @click="alertaInstrumentos(ficha);" v-if="ficha.estado=='Publicada'"><font-awesome-icon :icon="['fas', 'sync-alt']" /> Actualizar Instrumentos</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card" v-show="ficha.estado==='Por Publicar'">
                                    <div class="card-body">
                                        <h2 class="card-title">Agregar elementos de formulario <a href="#" v-b-tooltip.hover title="Elementos de respuesta para cada subsección"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></h2>
                                        <div class="section-elements__add section-elements__add--separate">
                                            <button type="button" class="btn btn-primary-outline btn-sm" title="Agregar respuesta corta" @click="selectElement('text')" v-if="!selected || tipo=='text'" :disabled="selectedSubseccion===''">
                                                <font-awesome-icon :icon="['fas', 'equals']" /> Respuesta corta
                                            </button>
                                            <button type="button" class="btn btn-primary-outline btn-sm" title="Agregar respuesta larga" v-if="!selected || tipo=='textarea'" @click="selectElement('textarea')" :disabled="selectedSubseccion===''">
                                                <font-awesome-icon :icon="['fas', 'align-left']" /> Respuesta larga
                                            </button>
                                            <div v-if="selected && (tipo=='text' || tipo=='textarea')">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" v-model="validacion.required">
                                                    <label class="form-check-label">
                                                        Respuesta obligatoria
                                                    </label>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-md-4">Máximo de caracteres</label>
                                                    <div class="col-md-4">
                                                        <input type="number" class="form-control" v-model="validacion.max"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-primary-outline btn-sm" title="Agregar opciones" v-if="!selected || tipo=='radio'" @click="selectElement('radio')" :disabled="selectedSubseccion===''">
                                                <font-awesome-icon :icon="['fas', 'dot-circle']" /> Opciones
                                            </button>
                                            <button type="button" class="btn btn-primary-outline btn-sm" title="Agregar chequeo" v-if="!selected || tipo=='checkbox'" @click="selectElement('checkbox')" :disabled="selectedSubseccion===''">
                                                <font-awesome-icon :icon="['fas', 'check-square']" /> Chequeo
                                            </button>
                                            <button type="button" class="btn btn-primary-outline btn-sm" title="Agregar selector" v-if="!selected || tipo=='select'" @click="selectElement('select')" :disabled="selectedSubseccion===''">
                                                <font-awesome-icon :icon="['fas', 'caret-square-down']" /> Selector
                                            </button>
                                            <button type="button" class="btn btn-primary-outline btn-sm" title="Agregar tabla" v-if="!selected || tipo=='table'" @click="addElement(selectedSubseccion, 'table')" :disabled="selectedSubseccion===''">
                                                <font-awesome-icon :icon="['fas', 'table']" /> Tabla
                                            </button>
                                            <div v-if="selected && (tipo=='radio' || tipo=='checkbox' || tipo=='select')">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" v-model="validacion.required">
                                                    <label class="form-check-label">
                                                        ¿Es requerido?
                                                    </label>
                                                </div>
                                                <div class="form-group">
                                                    <label>Escribe las opciones separadas con punto y coma</label>
                                                    <input type="text" class="form-control" v-model="validacion.opciones"/>
                                                </div>
                                            </div>
    <!--                                             <button type="button" class="btn btn-primary btn-sm" title="Agregar subir archivos" v-if="!selected || tipo=='file'" @click="selectElement('file')">
                                                    <font-awesome-icon :icon="['fas', 'upload']" /> Subir archivos
                                                </button> -->
                                        </div>
                                        <div class="">
                                            <div class="btns btns--right">
                                                <a @click="clearElement()" v-if="selected" class="btn btn--lightgrey btn-sm"><font-awesome-icon :icon="['fas', 'times']" /> Cancelar</a>
                                                <button type="button" class="btn btn-primary btn-sm" title="Guardar Validacion" @click="addElement(selectedSubseccion, tipo, validacion)" v-if="selected">
                                                    <font-awesome-icon :icon="['fas', 'check']" /> Guardar Validación
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import router from '../router/index.js'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Breadcrumb from '@/components/Breadcrumb.vue'

const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
const Ficha = resource('/ficha_instrumentos/api/crear_ficha', axios)
const Instrumento = resource('/ficha_instrumentos/api/asignar_instrumento', axios)

export default {
    name: 'CrearFicha',
    components: {
        ckeditor: CKEditor.component,
        Breadcrumb
    },
    // el: '.section-elements',
    data () {
        return {
            usuario: [],
            tiposficha: ['Diseño', 'Evaluación'],
            ficha: null,
            secciones: [],
            subsecciones: [],
            editar: false,
            formFicha: {},
            routeParams: this.$route.params.id,
            selected: false,
            element: null,
            tipo: '',
            validacion: {},
            selectedSeccion: '',
            selectedSubseccion: '',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', '|', 'undo', 'redo'],
                table: {
                    toolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language: 'es'
            },
            editorTableConfig: {
                toolbar: ['bold', 'italic', '|', 'insertTable', '|', 'undo', 'redo'],
                table: {
                    toolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language: 'es'
            },
            instrumentos: [],
            selectedInstrumentos: [],
            fichasAnios: [],
            selectedYear: null,
            scrollPosition: null,
            show: false,
            arr_stpda: {}
        }
    },
    methods: {
        getData () {
            UsuarioActual.query({}).then((res) => {
                this.usuario = res.data[0]
            })
            if (this.routeParams) {
                this.show = true
                Ficha.query({ id: this.routeParams }).then((res) => {
                    this.ficha = res.data.items[0]
                    this.show = false
                    // console.log('test')
                })
                    .catch((error) => {
                        console.log(error)
                        this.show = false
                    })
                Instrumento.query({}).then((res) => {
                    this.instrumentos = res.data
                })
                axios({
                    method: 'put',
                    url: '/ficha_instrumentos/api/crear_ficha/' + this.routeParams + '/anios/'
                })
                    .then((response) => {
                        this.fichasAnios = response
                    })
                    .catch((response) => {
                        console.log(response)
                    })
            }
        },
        createFicha () {
            this.formFicha.original = true
            // this.formFicha.creador = this.usuario.id
            axios({
                method: 'post',
                url: '/ficha_instrumentos/api/crear_ficha/',
                data: this.formFicha
            })
                .then((response) => {
                    router.push({ name: 'CrearSecciones', params: { id: response.data.id } })
                    location.reload()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Ficha creada exitosamente'
                    })
                })
                .catch((response) => {
                    console.log(response)
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Ficha'
                    })
                })
        },
        updateFicha (ficha) {
            this.show = true
            axios({
                method: 'put',
                url: '/ficha_instrumentos/api/crear_ficha/' + ficha.id + '/',
                data: ficha
            })
                .then((response) => {
                    this.getData()
                    this.editar = false
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Ficha actualizada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la Ficha'
                    })
                    console.log(response)
                })
        },
        deleteFicha (ficha) {
            this.show = true
            axios({
                method: 'delete',
                url: '/ficha_instrumentos/api/crear_ficha/' + ficha.id + '/'
            })
                .then((response) => {
                    router.push({ name: 'ListarFichas' })
                })
                .catch((response) => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Ficha'
                    })
                    console.log(response)
                })
        },
        createSeccion () {
            this.show = true
            axios({
                method: 'post',
                url: '/ficha_instrumentos/api/crear_seccion/',
                data: { ficha: this.routeParams }
            })
                .then((response) => {
                    this.getData()
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Sección creada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Sección'
                    })
                })
        },
        deleteSeccionAlert (seccion) {
            this.$swal({
                title: 'Vas a eliminar la sección',
                text: '¿Estás seguro? Se perderá la información y subsecciones asociadas',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteSeccion(seccion)
                }
            })
        },
        deleteSeccion (seccion) {
            this.show = true
            axios({
                method: 'delete',
                url: '/ficha_instrumentos/api/crear_seccion/' + seccion.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Sección eliminada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Sección'
                    })
                })
        },
        createSubSeccion (seccion) {
            this.show = true
            axios({
                method: 'post',
                url: '/ficha_instrumentos/api/crear_subseccion/',
                data: { seccion: seccion.id }
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Sub-Sección creada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Sub-Sección'
                    })
                })
        },
        deleteSubSeccionAlert (subseccion) {
            this.$swal({
                title: 'Vas a eliminar la Subsección',
                text: '¿Estás seguro? Se perderá toda la información y respuestas asociadas',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteSubSeccion(subseccion)
                }
            })
        },
        deleteSubSeccion (subseccion) {
            this.show = true
            axios({
                method: 'delete',
                url: '/ficha_instrumentos/api/crear_subseccion/' + subseccion.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Subsección eliminada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Subsección'
                    })
                })
        },
        updateSeccion (seccion) {
            seccion.original = true
            this.show = true
            axios({
                method: 'put',
                url: '/ficha_instrumentos/api/crear_seccion/' + seccion.id + '/',
                data: seccion
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Sección guardada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar la Sección'
                    })
                    console.log(response)
                })
        },
        updateSubSeccion (subseccion) {
            this.show = true
            subseccion.original = true
            axios({
                method: 'put',
                url: '/ficha_instrumentos/api/crear_subseccion/' + subseccion.id + '/',
                data: subseccion
            })
                .then((response) => {
                    this.show = false
                    this.clearElement()
                    // this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Sub-Sección guardada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar la Sub-Sección'
                    })
                    console.log(response)
                })
        },
        funcion_stpda (subseccion) {
            this.arr_stpda[subseccion.id] = subseccion.elementos.length
            // console.log(this.arr_stpda)
        },
        createElemento (subseccion, elemento) {
            this.show = true
            axios({
                method: 'post',
                url: '/ficha_instrumentos/api/crear_elemento/',
                data: { subseccion: subseccion.id, elemento: elemento }
            })
                .then((response) => {
                    this.show = false
                    // this.getData()
                    this.arr_stpda[subseccion.id] += 1
                    // console.log(this.arr_stpda[subseccion.id])
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Elemento creado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Elemento'
                    })
                    console.log(response)
                })
        },
        updateElemento (elemento) {
            this.show = true
            if (elemento) {
                axios({
                    method: 'put',
                    url: '/ficha_instrumentos/api/crear_elemento/' + elemento.id + '/',
                    data: elemento
                })
                    .then((response) => {
                        this.show = false
                        this.clearElement()
                        this.getData()
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Elemento guardado exitosamente'
                        })
                    })
                    .catch((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al guardar el Elemento'
                        })
                        console.log(response)
                    })
            }
        },
        selectElement (tipo) {
            this.tipo = tipo
            this.selected = true
        },
        addElement (subseccion, tipo, validacion) {
            // console.log(subseccion)
            // console.log(subseccion.elementos.length) // elementos length
            // if (subseccion.elementos.length > 1) {
            // console.log(this.arr_stpda)
            if (this.arr_stpda[subseccion.id] >= 1) {
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Subseccion no puede tener mas de un campo'
                })
            } else {
                var element = {}
                var val = ''
                var opt = ''
                if (tipo === 'table') {
                    this.tipo = tipo
                    element = {
                        type: tipo
                    }
                } else if (tipo !== 'table') {
                    if (validacion.required) {
                        val = val + 'required'
                    }
                    if (validacion.max) {
                        val = val + '|max:' + validacion.max + ',length'
                    }
                    if (validacion.opciones) {
                        var values = validacion.opciones.split(';')
                        var opts = {}
                        values.filter((item) => {
                            opts[item] = item
                            // opt.push(opts)
                        })
                        opt = opts
                    }
                    element = {
                        type: tipo,
                        validation: val
                    }
                    if (validacion.opciones) {
                        element.options = opt
                    }
                }
                this.element = element
                this.createElemento(subseccion, this.element)
                this.updateSubSeccion(subseccion)
                this.clearElement()
                this.getData()
            }
        },
        clearElement () {
            this.element = null
            this.selected = false
            this.tipo = ''
            this.validacion = {}
        },
        deleteElementAlert (subseccion, elemento) {
            this.$swal({
                title: 'Vas a eliminar el elemento de respuesta',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteElement(subseccion, elemento)
                }
            })
        },
        deleteElement (subseccion, elemento) {
            this.show = true
            axios({
                method: 'delete',
                url: '/ficha_instrumentos/api/crear_elemento/' + elemento.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.arr_stpda[subseccion.id] -= 1
                    // console.log(this.arr_stpda[subseccion.id])
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Elemento eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Elemento'
                    })
                })
        },
        alertaInstrumentos (ficha) {
            this.$swal({
                title: 'Vas a actualizar los instrumentos, ¿estás seguro?',
                text: 'Si eliminaste un instrumento y ya existen cambios realizados, se perderán.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.updateFicha(ficha)
                }
            })
        },
        deleteAlert (ficha) {
            this.$swal({
                title: 'Vas a eliminar la ficha',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFicha(ficha)
                }
            })
        },
        copyFicha (anio, accion) {
            var url = ''
            var formCopy = {}
            formCopy.fichaId = this.ficha.id
            formCopy.anio = anio
            if (accion === 'duplicar') {
                url = '/ficha_instrumentos/api/crear_ficha/' + this.ficha.id + '/duplicar/'
            } else if (accion === 'copiar') {
                url = '/ficha_instrumentos/api/crear_ficha/' + this.ficha.id + '/duplicar_vacio/'
            }
            this.show = true
            axios({
                method: 'put',
                url: url,
                data: formCopy
            })
                .then((response) => {
                    this.$bvModal.hide('modal-duplicar')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Ficha copiada exitosamente'
                    })
                    const id = JSON.parse(response.data)[0].pk
                    this.$router.push({ path: `/crear_ficha/${id}` })
                    location.reload()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al copiar la ficha'
                    })
                    console.log(response)
                })
        },
        updateScroll () {
            this.scrollPosition = window.scrollY
        }
    },
    mounted () {
        this.getData()
        window.addEventListener('scroll', this.updateScroll)
    },
    computed: {
        years () {
            const year = new Date().getFullYear()
            var temp = Array.from({ length: year - 2005 }, (value, index) => 2011 + index)
            var index = ''
            if (this.fichasAnios.data) {
                this.fichasAnios.data.forEach(anio => {
                    index = temp.indexOf(anio)
                    temp.splice(index, 1)
                })
            }
            return temp
        }
    }
}
</script>

<template>
    <div>
        <Breadcrumb nombreActual="Material de Interés"></Breadcrumb>
        <section class="ficha">
            <div class="container2">
                <div class="row justify-content-center">
                    <!-- v-for material in materiales -->
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Documento Metodológico Ciclo de Aprendizaje
                            </div>
                            <div class="card-r-text">
                                Documento que describe el sistema de diseño, monitoreo y evaluación de los programas e instrumentos en materias de Ciencia, Tecnología, Conocimiento e Innovación que implementa la Oficina de Estudios y Estadísticas del Ministerio de Ciencia.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="https://api.observa.minciencia.gob.cl/api/galeria/13/archivo/" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Manual de Uso de Intranet
                            </div>
                            <div class="card-r-text">
                                Documento que describe los diferentes espacios de trabajo de la plataforma diseñada para implementar el Ciclo de Aprendizaje para la evaluación de instrumentos y prigramas en materias de Ciencia, Tecnología, Conocimiento e Innovación.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="https://api.observa.minciencia.gob.cl/api/galeria/12/archivo/" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Capacitación del Ciclo e Intranet
                            </div>
                            <div class="card-r-text">
                                 Presentación de capacitación del Ciclo de Aprendizaje y su implementación a través de la intranet. En ella podrás encontrar link al video de la capacitación.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="https://api.observa.minciencia.gob.cl/api/galeria/11/archivo/" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<ul>
                    <li>
                        Material 1: <a href="">URL</a>
                    </li>
                    <li>
                        Material 2: <a href="">URL</a>
                    </li>
                    <li>
                        Material 3: <a href="">URL</a>
                    </li>
                </ul>-->
            </div>
        </section>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    name: 'Reportes',
    components: {
        Breadcrumb
    },
    async beforeCreate () {

    },
    data () {
        return {
        }
    },
    methods: {
    },
    mounted () {
    },
    computed: {
    }
}
</script>

<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-10">
                    <Breadcrumb v-if="monitoreo && monitoreo.instrumento__nombre" :nombreActual="'Monitoreo de Desempeño: ' + monitoreo.instrumento__nombre" nombreAnterior="Monitoreos" urlAnterior="/monitoreo_instrumentos"></Breadcrumb>
                </div>
                <div class="col-md-2">
                    <div class="mt-4 float-right" v-if="monitoreo && isAdmin">
                        <a v-if="monitoreo.activo" class="btn btn-danger" @click="monitoreo.activo=!monitoreo.activo;updateMonitoreo(monitoreo)"><font-awesome-icon :icon="['fas', 'ban']" /> Inhabilitar</a>
                        <a v-if="!monitoreo.activo" class="btn btn-secondary mr-2" @click="monitoreo.activo=!monitoreo.activo;updateMonitoreo(monitoreo)"><font-awesome-icon :icon="['fas', 'circle']" /> Habilitar</a>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-tipo-indicador" size="sm" title="Seleccionar Tipo de Indicador">
            <div class="row">
                <div class="form-group col-md-10">
                    <v-select :options="tipoIndicadores" v-model="form.tipo_indicador" label="nombre" placeholder="Seleccionar"></v-select>
                </div>
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModalTipoIndicador()">
                    <font-awesome-icon :icon="['fas', 'times']" />
                </b-button>
                <ModalIndicador v-if="changed && monitoreo" modal="modal-create-indicador" :monitoreoInstrumento="monitoreo.id" :instrumento="monitoreo.instrumento" :tipoIndicador="changed.id" :tipoIndicadorNombre="changed.nombre" :editar="false" :formIndicador="{}" @accept="getData();closeModalTipoIndicador();"></ModalIndicador>
            </template>
        </b-modal>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <div class="section__container">
                            <div class="card card--section" v-if="monitoreo">
                                <div class="card-header">
                                    <h2 class="card-title">{{monitoreo.monitoreo__nombre | uppercase}} - {{monitoreo.anio}} <span class="badge" v-bind:class="{ 'bg-en-proceso': monitoreo.estado === 'En Proceso', 'bg-por-validar': monitoreo.estado ==='Por Validar', 'bg-validada': monitoreo.estado === 'Validada', 'bg-bloqueada': monitoreo.estado === 'Bloqueada'}">{{monitoreo.estado}}</span></h2>
                                    <h3 class="card-subtitle">{{monitoreo.instrumento__nombre | uppercase}}</h3>
                                    <p style="text-align: justify;"> {{monitoreo.monitoreo__descripcion}}</p>
                                    <ModalComentarios :monitoreoInstrumento="monitoreo.id" :instrumento="monitoreo.instrumento" titulo="Monitoreo Desempeño" :modal="'monitoreoinstrumento-' + monitoreo.id"></ModalComentarios>
                                </div>
                                <div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h2 class="card-title">Indicadores</h2>
                                        </div>
                                        <div class="col-md-6">
                                            <a v-show="monitoreo.estado!=='Validada'" @click="openModalTipoIndicador()" class="btn btn-secondary float-right">
                                                <font-awesome-icon :icon="['fas', 'file']" />
                                                Crear Indicador
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            Filtrar por:
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <v-select :options="tipoIndicadores" v-model="formSearch.tipo_indicador" label="nombre" :reduce="nombre => nombre.id" placeholder="Seleccionar"></v-select>
                                        </div>
                                        <div class="col-md-6">
                                                <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año en el que se reportó el Indicador"></v-select>
                                            </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span  v-if="monitoreo.indicadores && monitoreo.indicadores.length === 0" class="badge bg-alert"> No existen Indicadores asociados</span>
                                            <ul class="monitoreo-list" v-if="monitoreo.indicadores && monitoreo.indicadores.length > 0">
                                                <li v-for="indicador in monitoreo.indicadores" v-bind:key="indicador.id">
                                                    <div class="row">
                                                        <div class="col-md-7">
                                                            <router-link :to="'/monitoreoinstrumento/' + monitoreoId + '/' + instrumentoId + '/' + indicador.id + '/' + monitoreo.id + '/' + anio" class="file-name">
                                                                <span class="file-name__text">
                                                                    {{ indicador.nombre }}
                                                                </span>
                                                                <font-awesome-icon :icon="['fas', 'chevron-right']"/>
                                                            </router-link>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div class="float-right">
                                                                <span class="file-name__text">
                                                                    <span class="badge bg-badge bg-success">{{indicador.tipo_indicador__nombre}}</span>
                                                                </span>
                                                                <span class="file-name__text">
                                                                    <span class="badge bg-badge">Año de reporte: {{indicador.anio}}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a @click="deleteAlert(indicador)" role="button" v-if="!['Validada', 'Bloqueada'].includes(monitoreo.estado) && !indicador.ficha" class="btn btn-danger btn-sm float-right"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" v-if="monitoreo">
                        <div class="card">
                            <div class="card-body">
                                <div class="section-elements">
                                    <h2 class="card-title">Información adicional</h2>
                                    <p class="mb-3">Estado del Monitoreo:
                                        <span :class="{'badge bg-validada': monitoreo.estado === 'Validada', 'badge bg-bloqueada': monitoreo.estado === 'Bloqueada'}" v-if="['Validada', 'Bloqueada'].includes(monitoreo.estado) && !isAdmin">
                                            {{monitoreo.estado}}
                                        </span>
                                        <select v-model="monitoreo.estado" class="form-control" v-if="isAdmin || !['Validada', 'Bloqueada'].includes(monitoreo.estado)">
                                            <option v-for="estado in estados" v-bind:key="estado" :value="estado" :disabled="(!isAdmin && estado==='Validada') || (!isAdmin && estado==='Bloqueada')">{{estado}}</option>
                                        </select>
                                    </p>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" v-model="monitoreo.validado_revisor" @click="monitoreo.validado_revisor=!monitoreo.validado_revisor" :disabled="!isAdminAgencia" />
                                        <label class="form-check-label">
                                        ¿Validado por Encargado Agencia / Encargado Subdirección(Gerencia)?
                                        </label>
                                    </div>
                                    <div class="btns btns--right">
                                        <a @click="updateMonitoreo(monitoreo)" class="btn btn-primary" v-if="monitoreo.estado!=='Validada' || isAdmin"><font-awesome-icon :icon="['fas', 'check']"/> Guardar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalComentarios from '@/components/ModalComentarios.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import ModalIndicador from '@/components/ModalIndicador.vue'

const MonitoreoInstrumento = resource('/monitoreo/api/monitoreo_instrumento', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
const TipoIndicador = resource('/indicadores/api/listar_tipo_indicador', axios)
const IndicadorAnio = resource('/monitoreo/api/ver_anio_indicadores', axios)

export default {
    name: 'MonitoreoInstrumento',
    components: {
        ModalComentarios,
        Breadcrumb,
        ModalIndicador
    },
    data () {
        return {
            monitoreo: {},
            monitoreoId: this.$route.params.monitoreoId,
            instrumentoId: this.$route.params.instrumentoId,
            anio: this.$route.params.anio,
            usuario: {},
            isAdmin: false,
            isAdminAgencia: false,
            estados: [
                'En Proceso',
                'Por Validar',
                'Validada',
                'Bloqueada'
            ],
            form: {},
            changed: null,
            tipoIndicadores: [],
            formSearch: {},
            anios: [],
            show: true
        }
    },
    methods: {
        getData () {
            MonitoreoInstrumento.query({ monitoreo: this.monitoreoId, instrumento: this.instrumentoId, anio: this.anio, indicadores__tipo_indicador: this.formSearch.tipo_indicador, indicadores__anio: this.formSearch.anio }).then((res) => {
                this.monitoreo = JSON.parse(JSON.stringify(res.data))[0]
                this.show = false
            }, (error) => {
                console.log(error)
                this.show = false
            })
        },
        updateMonitoreo (monitoreo) {
            this.show = true
            axios({
                method: 'put',
                url: '/monitoreo/api/monitoreo_instrumento/' + monitoreo.id + '/',
                data: monitoreo
            })
                .then((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Monitoreo guardado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar el Monitoreo'
                    })
                    console.log(response)
                })
        },
        openModalTipoIndicador () {
            this.$root.$emit('bv::show::modal', 'modal-tipo-indicador')
        },
        closeModalTipoIndicador () {
            this.$root.$emit('bv::hide::modal', 'modal-tipo-indicador')
        },
        deleteAlert (indicador) {
            this.$swal({
                title: 'Vas a eliminar el Indicador',
                text: '¿Estás seguro? Se perderán todos los datos',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteIndicador(indicador)
                }
            })
        },
        deleteIndicador (indicador) {
            this.show = true
            axios({
                method: 'delete',
                url: '/monitoreo/api/crear_resultados_indicador_anio/' + indicador.id + '/',
                data: { monitoreo_instrumento: this.monitoreo.id }
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Indicador eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Indicador'
                    })
                })
        }
    },
    mounted () {
        this.getData()
        TipoIndicador.query({}).then((res) => {
            this.tipoIndicadores = res.data
        }, function (error) {
            console.log(error)
        })
        IndicadorAnio.query({ monitoreo_instrumento__monitoreo: this.monitoreoId, monitoreo_instrumento__instrumento: this.instrumentoId, monitoreo_instrumento__monitoreo__anio: this.anio }).then((res) => {
            this.anios = res.data
        })
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            }
            if (this.usuario.tipo_usuario === 'Encargado Agencia' || this.usuario.tipo_usuario === 'Encargado Subdirección' || this.usuario.is_superuser) {
                this.isAdminAgencia = true
            }
        })
        this.$watch('form.tipo_indicador', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.changed = newLocale
            }
        }, { immediate: true })
        this.$watch('formSearch.tipo_indicador', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
        this.$watch('formSearch.anio', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
    }
}
</script>

<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-10">
                    <Breadcrumb v-if="monitoreo && monitoreo.agencia__nombre" :nombreActual="'Monitoreo de Procesos: ' + monitoreo.agencia__nombre" nombreAnterior="Monitoreos" urlAnterior="/monitoreo_instrumentos"></Breadcrumb>
                </div>
                <div class="col-md-2">
                    <div class="mt-4 float-right" v-if="monitoreo && isAdmin">
                        <a v-if="monitoreo.activo" class="btn btn-danger" @click="monitoreo.activo=!monitoreo.activo;updateMonitoreo(monitoreo)"><font-awesome-icon :icon="['fas', 'ban']" /> Inhabilitar</a>
                        <a v-if="!monitoreo.activo" class="btn btn-secondary mr-2" @click="monitoreo.activo=!monitoreo.activo;updateMonitoreo(monitoreo)"><font-awesome-icon :icon="['fas', 'circle']" /> Habilitar</a>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-cargar-planillas" size="lg" title="Cargar Planillas de Respuestas">
            <div class="row">
                <div class="form-group col-md-10">
                    <label>Planillas*</label>
                    <b-form-file multiple :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los documentos" placeholder="No hay documentos seleccionados" ref="files">
                       <template slot="file-name" slot-scope="{ names }">
                             <b-badge variant="light">{{ names[0] }}</b-badge>
                             <b-badge v-if="names && names.length > 1" variant="light" class="ml-1">
                               + {{ names.length - 1 }}
                             </b-badge>
                       </template>
                    </b-form-file>
                </div>
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModal()">
                    Cancelar
                </b-button>
                <b-button @click="uploadFile()" class="btn btn-primary" :disabled="files.length===0">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></b-button>
            </template>
        </b-modal>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <div class="section__container">
                            <div class="card card--section" v-if="monitoreo">
                                <div class="card-header">
                                    <h2 class="card-title">{{monitoreo.monitoreo__nombre | uppercase}} - {{monitoreo.anio}} <span class="badge" v-bind:class="{ 'bg-en-proceso': monitoreo.estado === 'En Proceso', 'bg-por-validar': monitoreo.estado ==='Por Validar', 'bg-validada': monitoreo.estado === 'Validada', 'bg-bloqueada': monitoreo.estado === 'Bloqueada'}">{{monitoreo.estado}}</span></h2>
                                    <h3 class="card-subtitle">{{monitoreo.agencia__nombre | uppercase}}</h3>
                                    <p style="text-align: justify;"> {{monitoreo.monitoreo__descripcion}}</p>
                                    <ModalComentarios :monitoreoInstrumento="monitoreo.id" :agencia="agenciaId" titulo="Monitoreo Procesos" :modal="'monitoreoprocesos-' + monitoreo.id"></ModalComentarios>
                                </div>
                                <div>
                                </div>
                                <div class="card-body">
                                    <h2 class="card-title">Planilla(s) de Respuesta</h2>
                                    <a @click="openModal()" class="btn btn-primary pull-right" v-if="!['Validada', 'Bloqueada'].includes(monitoreo.estado)">
                                        <font-awesome-icon :icon="['fas', 'file']" /> Cargar planillas
                                    </a>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="alert alert-info mt-3" role="alert" v-if="monitoreo.planillarespuestas && monitoreo.planillarespuestas.length===0">
                                              No existen Planillas cargadas
                                            </div>
                                            <ul class="files-list" v-if="monitoreo.planillarespuestas && monitoreo.planillarespuestas.length > 0">
                                                <li v-for="planilla in monitoreo.planillarespuestas" v-bind:key="planilla.id">
                                                    <a  class="file-name"  :href="baseUrl + planilla.documento__url" target="_blank" download>
                                                        <font-awesome-icon :icon="['fas', 'file']"/>
                                                        <span class="file-name__text">
                                                            {{ planilla.nombre }}
                                                            <br>
                                                            <i v-if="planilla.existe">{{planilla.user_create__get_nombre_completo}} — <strong>{{planilla.fechahora_created | dateTimeFormat}}</strong></i>
                                                            <i v-if="!planilla.existe"><strong>Inhabilitado por:</strong> {{planilla.user_delete__get_nombre_completo}} — <strong>{{planilla.fechahora_deleted | dateTimeFormat}}</strong></i>
                                                        </span>
                                                    </a>
                                                    <a @click="alertaPlanillaInhabilitar(planilla);" class="ban-file" v-if="(isAdmin || (!['Validada', 'Bloqueada'].includes(monitoreo.estado)) && planilla.existe)">
                                                        <font-awesome-icon :icon="['fas', 'ban']" />
                                                    </a>
                                                    <a @click="alertaPlanilla(planilla);" v-if="isAdmin" class="delete-file">
                                                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" v-if="monitoreo">
                        <div class="card">
                            <div class="card-body">
                                <div class="section-elements">
                                    <h2 class="card-title">Planilla(s) a descargar</h2>
                                    <ul class="files-list">
                                        <li v-for="planilla in monitoreo.planillas" v-bind:key="planilla.id">
                                            <a  class="file-name" :href="baseUrl + planilla.documento__url" target="_blank" download>
                                                <font-awesome-icon :icon="['fas', 'file']"/>
                                                <span class="file-name__text">
                                                    {{ planilla.nombre }}
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                    <h2 class="card-subtitle">Información adicional</h2>
                                    <p class="mb-3"><label>Estado del Monitoreo:</label>
                                        <span class="badge bg-success" v-if="monitoreo.estado==='Validada' && !isAdmin">
                                            {{monitoreo.estado}}
                                        </span>
                                        <select v-model="monitoreo.estado" class="form-control" v-if="isAdmin || monitoreo.estado !== 'Validada'">
                                            <option v-for="estado in estados" v-bind:key="estado" :value="estado" :disabled="!isAdmin && estado==='Validada' || !isAdmin && estado==='Bloqueada'">{{estado}}</option>
                                        </select>
                                    </p>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" v-model="monitoreo.validado_revisor" @click="monitoreo.validado_revisor=!monitoreo.validado_revisor" :disabled="!isAdminAgencia || ['Validada', 'Bloqueada'].includes(monitoreo.estado)" />
                                        <label class="form-check-label">
                                        ¿Validado por Encargado Agencia / Encargado Subdirección(Gerencia)?
                                        </label>
                                    </div>
                                    <div class="btns btns--right">
                                        <a @click="updateMonitoreo(monitoreo)" class="btn btn-primary" v-if="monitoreo.estado!=='Validada' || isAdmin"><font-awesome-icon :icon="['fas', 'check']"/> Guardar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalComentarios from '@/components/ModalComentarios.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

const MonitoreoInstrumento = resource('/monitoreo/api/monitoreo_instrumento', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'MonitoreoProcesos',
    components: {
        ModalComentarios,
        Breadcrumb
    },
    data () {
        return {
            monitoreo: {},
            monitoreoId: this.$route.params.monitoreoId,
            agenciaId: this.$route.params.agenciaId,
            anio: this.$route.params.anio,
            usuario: {},
            isAdmin: false,
            isAdminAgencia: false,
            estados: [
                'En Proceso',
                'Por Validar',
                'Validada',
                'Bloqueada'
            ],
            files: [],
            baseUrl: process.env.VUE_APP_BASEURL,
            acceptedFiles: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/x-rar-compressed,application/zip,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg',
            show: true
        }
    },
    methods: {
        getData () {
            MonitoreoInstrumento.query({ monitoreo: this.monitoreoId, agencia: this.agenciaId, anio: this.anio }).then((res) => {
                this.monitoreo = JSON.parse(JSON.stringify(res.data))[0]
                this.show = false
            }, (error) => {
                console.log(error)
                this.show = false
            })
        },
        updateMonitoreo (monitoreo) {
            this.show = true
            axios({
                method: 'put',
                url: '/monitoreo/api/monitoreo_instrumento/' + monitoreo.id + '/',
                data: monitoreo
            })
                .then((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Monitoreo guardado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar el Monitoreo'
                    })
                    console.log(response)
                })
        },
        openModal () {
            this.$root.$emit('bv::show::modal', 'modal-cargar-planillas')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-cargar-planillas')
        },
        uploadFile () {
            var formData = new FormData()
            for (var i = 0; i < this.files.length; i++) {
                var file = this.files[i]
                formData.append('documento[' + i + ']', file)
            }
            formData.append('monitoreoinstrumento', this.monitoreo.id)
            this.show = true
            axios.post('/monitoreo/api/cargar_planilla_respuesta/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.closeModal()
                    this.files = []
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Planillas cargados exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al cargar los Planillas'
                    })
                })
        },
        deleteFile (planilla) {
            this.show = true
            axios({
                method: 'delete',
                url: '/monitoreo/api/cargar_planilla_respuesta/' + planilla.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Planilla eliminada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Planilla'
                    })
                })
        },
        alertaPlanilla (planilla) {
            this.$swal({
                title: 'Vas a eliminar esta planilla, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFile(planilla)
                }
            })
        },
        alertaPlanillaInhabilitar (planilla) {
            this.$swal({
                title: 'Vas a inhabilitar esta planilla, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.inhabilitarPlanilla(planilla)
                }
            })
        },
        inhabilitarPlanilla (planilla) {
            axios.put('/monitoreo/api/cargar_planilla_respuesta/' + planilla.id + '/inhabilitar_planilla/').then((res) => {
                if (res.status === 200) {
                    this.getData()
                }
            })
                .catch((res) => {
                    console.log(res)
                })
        }
    },
    mounted () {
        this.getData()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            }
            if (this.usuario.tipo_usuario === 'Encargado Agencia' || this.usuario.tipo_usuario === 'Encargado Subdirección' || this.usuario.is_superuser) {
                this.isAdminAgencia = true
            }
        })
    }
}
</script>

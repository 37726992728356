<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Unidades de Medida" nombreAnterior="Panel de Administración" urlAnterior="/panel_administracion"></Breadcrumb>
        <b-modal id="modal-create-unidad" size="lg" title="Unidad de Medida">
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Nombre*  <!-- HTML to write -->
                        <a href="#" v-b-tooltip.hover title="Nombre que recibe la unidad"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                    <input type="text" name="" class="form-control" v-model="unidad.nombre" placeholder="ej. Porcentaje">
                </div>
                <div class="form-group col-md-6">
                    <label>Unidad
                        <a href="#" v-b-tooltip.hover title="Unidad en la que se mide, %/N/Sumatoria"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                    <input type="text" name="" class="form-control" v-model="unidad.unidad" placeholder="ej. %">
                </div>
                <div class="form-group col-md-6">
                    <label>Fórmula*
                        <a href="#" v-b-tooltip.hover title="Formula con la que se evaluará"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                    <input type="text" name="" class="form-control" v-model="unidad.formula" placeholder="ej. (A/B)*100">
                </div>
                <div class="form-group col-md-6">
                    <label>Cantidad de Variables
                        <a href="#" v-b-tooltip.hover title="Cantidad de variables que se presentan en la fórmula, en caso de no saber cuántas variables, dejar en blanco. (Ej. Sumatoria: A+B+..+N)"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                    </label>
                    <input type="number" name="" min="0" class="form-control" v-model="unidad.cantidad_variables" placeholder="ej. 2 (A y B = 2)">
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <b-button size="md" variant="outline-secondary" @click="cancel()">
                    Cancelar
                </b-button>
                <a v-if="!editar" @click="createUnidadMedida();" class="btn btn-primary">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
                <a v-if="editar" @click="updateUnidadMedida(unidad);" class="btn btn-primary">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
            </template>
        </b-modal>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card--button">
                            <b-button v-b-modal.modal-create-unidad class="card--button__btn" @click="openCreate()">
                                <font-awesome-icon :icon="['fas', 'plus']" />
                                <span class="card--button__text">
                                    Crear Unidad Medida
                                </span>
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <!-- <div class="section__container">
                                    <div class="card card--section">
                                        <div class="card-header"> -->
                                        <div class="table-responsive">
                                            <table class="table table-stripped table-hover table-bordered" id="tabla-unidad" v-if="unidadMedidas.length>0">
                                                <thead>
                                                    <tr>
                                                        <td>Nombre</td>
                                                        <td>Unidad</td>
                                                        <td>Fórmula</td>
                                                        <td>Cantidad de Variables</td>
                                                        <td>Acción</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="unidad in unidadMedidas" v-bind:key="unidad.id">
                                                        <td>{{unidad.nombre}}</td>
                                                        <td>{{unidad.unidad}}</td>
                                                        <td>{{unidad.formula}}</td>
                                                        <td>{{unidad.cantidad_variables}}</td>
                                                        <td>
                                                        <div class="btns mt-0">
                                                            <b-button v-b-modal.modal-create-unidad variant="primary" class="btn btn--white btn-sm" @click="openUpdate(unidad)">
                                                                <font-awesome-icon :icon="['fas', 'edit']" /> Editar
                                                            </b-button>
                                                            <a @click="deleteAlert(unidad)" role="button" class="btn btn-danger btn-sm"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                            <!-- <router-link :to="'/editar_usuario/' + tipo.id" class="btn btn--white"><font-awesome-icon :icon="['fas', 'edit']" /> Editar</router-link> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                              <b-pagination
                                              v-model="currentPage"
                                              :total-rows="rows"
                                              :per-page="perPage"
                                              aria-controls="tabla-unidad"
                                              ></b-pagination>
                                        <!-- </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'
// import router from '../router/index.js'

const UnidadMedida = resource('/indicadores/api/crear_unidad_medida', axios)
export default {
    name: 'CrearUnidadMedida',
    components: {
        Breadcrumb
    },
    data () {
        return {
            unidadMedidas: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            unidad: {},
            editar: false,
            show: true
        }
    },
    methods: {
        getData () {
            UnidadMedida.query({ page: this.currentPage, per_page: 10 }).then((res) => {
                this.unidadMedidas = res.data.items
                this.perPage = res.data.paginate.per_page
                this.rows = res.data.paginate.count
                this.show = false
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
        },
        createUnidadMedida () {
            this.show = true
            axios({
                method: 'post',
                url: '/indicadores/api/crear_unidad_medida/',
                data: this.unidad
            })
                .then((response) => {
                    this.show = false
                    this.$root.$emit('bv::hide::modal', 'modal-create-unidad')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Unidad de Medida creada exitosamente'
                    })
                    this.getData()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Unidad de Medida'
                    })
                })
        },
        updateUnidadMedida (unidad) {
            this.show = true
            axios({
                method: 'put',
                url: '/indicadores/api/crear_unidad_medida/' + unidad.id + '/',
                data: unidad
            })
                .then((response) => {
                    this.show = false
                    this.$root.$emit('bv::hide::modal', 'modal-create-unidad')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Unidad de Medida actualizado exitosamente'
                    })
                    this.getData()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la Unidad de Medida'
                    })
                })
        },
        openCreate () {
            this.editar = false
            this.unidad = {}
        },
        openUpdate (unidad) {
            this.editar = true
            this.unidad = unidad
        },
        deleteAlert (unidad) {
            this.$swal({
                title: 'Vas a eliminar la Unidad de Medida',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteUnidad(unidad)
                }
            })
        },
        deleteUnidad (unidad) {
            axios({
                method: 'delete',
                url: '/indicadores/api/crear_unidad_medida/' + unidad.id + '/'
            })
                .then((response) => {
                    this.getData()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Unidad'
                    })
                    console.log(response)
                })
        }
    },
    mounted () {
        this.$watch('currentPage', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
    }
}
</script>

<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Agencias" nombreAnterior="Panel de Administración" urlAnterior="/panel_administracion"></Breadcrumb>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card--section">
                            <div class="card-header">
                                <h2 class="card-title d-inline-block">Agencias</h2>
                                <router-link to="/crear_agencia" class="btn btn-primary float-right">
                                    <font-awesome-icon :icon="['fas', 'plus']" /> Crear Agencias
                                </router-link>
                            </div>
                            <div class="card-body">
                                <div class="agencias__container">
                                    <div class="agencias__header">
                                        <div class="row no-gutters">
                                            <div class="col-md-2">Nombre</div>
                                            <!-- <div class="col-md-2">Descripción</div> -->
                                            <div class="col-md-10">
                                                <div class="row">
                                                    <div class="col-md-4">Subdirección/Gerencia</div>
                                                    <!-- <div class="col-md-3">Descripción Subdirección</div> -->
                                                    <div class="col-md-8">
                                                        <div class="row no-gutters">
                                                            <!--<div class="col-md-6">-->
                                                                <div class="col-md-7">
                                                                    Instrumento
                                                                </div>
                                                                <div class="col-md-5" id="totheright2">
                                                                    <b-button v-if="isSuperAdmin && !alterOrder" variant="outline-primary" @click="SettingsMode()"><font-awesome-icon :icon="['fas', 'sort']"/> Reorganizar</b-button>
                                                                   <b-button v-if="isSuperAdmin && alterOrder" variant="outline-secondary" @click="SettingsMode()"><font-awesome-icon :icon="['fas', 'check']"/> Finalizar</b-button>
                                                                </div>
                                                            <!--</div>-->
                                                            <!-- <div class="col-md-7">
                                                                <div>Descripción Instrumento</div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-2">Acción</div> -->
                                        </div>
                                    </div>
                                    <ul class="agencia__list" id="lista-agencias">
                                        <li class="agencia__item" v-for="agencia in agenciasQuery" v-bind:key="agencia.id">
                                            <div class="row no-gutters">
                                                <div class="col-md-2">
                                                    <div class="row">
                                                        <div class="col-md-1" style="margin-top: 2px">
                                                            <font-awesome-icon v-if="!agencia.activo" :icon="['fas', 'ban']" style="color: red;"/><font-awesome-icon v-if="agencia.activo" :icon="['fas', 'circle']" style="color: #63C894;"/>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <span class="agencia__item-name"> {{agencia.nombre}}</span>
                                                            <router-link :to="'/crear_agencia/' + agencia.id" class="btn btn-secondary btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /> Editar</router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-2">
                                                    <span class="agencia__item-description">{{agencia.descripcion}}</span>
                                                </div> -->
                                                <div class="col-md-10">
                                                    <custom-div class="agencia__subdireccion" v-for="subdireccion in agencia.subdirecciones" :visible="subdireccion.activo===true" v-bind:key="subdireccion.id">
                                                        <div class="row no-gutters">
                                                            <div class="col-md-4">
                                                                <div class="row">
                                                                    <div class="col-md-1">
                                                                        <font-awesome-icon v-if="!subdireccion.activo" :icon="['fas', 'ban']" style="color: red;"/><font-awesome-icon v-if="subdireccion.activo" :icon="['fas', 'circle']" style="color: #63C894;"/>
                                                                    </div>
                                                                    <div class="col-md-7">
                                                                        <span class="agencia__subdireccion-name"> {{subdireccion.nombre}}</span>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <b-button v-if="alterOrder" class="btn btn-secondary btn-sm" @click="MoverSub(subdireccion)"><font-awesome-icon :icon="['fas', 'sort']"/> Mover</b-button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-md-3">
                                                                <span class="agencia__subdireccion-description">{{subdireccion.descripcion}}</span>
                                                            </div> -->
                                                            <div class="col-md-8">
                                                                <div class="agencia__instrumentos">
                                                                    <ul class="agencia__instrumentos-list">
                                                                        <!-- <li class="agencia__instrumentos-item" v-for="instrumento in subdireccion.instrumentos" v-bind:key="instrumento.id" > -->
                                                                        <custom-li class="agencia__instrumentos-item" v-for="instrumento in subdireccion.instrumentos" :visible="instrumento.activo===true" v-bind:key="instrumento.id">
                                                                            <div class="row">
                                                                                <div class="col-md-1">
                                                                                    <font-awesome-icon v-if="!instrumento.activo" :icon="['fas', 'ban']" style="color: red;"/><font-awesome-icon v-if="instrumento.activo" :icon="['fas', 'circle']" style="color: #63C894;"/>
                                                                                </div>
                                                                                <div class="col-md-8">
                                                                                    <span class="agencia__instrumentos-name"> {{instrumento.nombre}}</span>
                                                                                </div>
                                                                                <div class="col-md-3" id="totheright">
                                                                                    <b-button v-if="alterOrder" class="btn btn-secondary btn-sm" @click="Mover(instrumento)"><font-awesome-icon :icon="['fas', 'sort']"/> Mover</b-button>
                                                                                </div>
                                                                            </div>
                                                                        </custom-li>
                                                                        <!-- </li> -->
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </custom-div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="lista-agencias" class="mt-3">
                                    </b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup" v-if="popUpTrigger" :TogglePopup = " () => TogglePopup()">
                <div class="popup-inner">
                    <div class="boton_cierre">
                        <button type="button" aria-label="Close" class="close" id="minorfx" @click="cancelarOperacion()">×</button>
                    </div>
                    <div class="row" id="espacio_modal">
                        <div class="form-group col-md-12">
                            Instrumento:
                            <h3><b>{{instrumento.nombre}}</b></h3>
                        </div>
                        <div class="form-group col-md-12">
                            Subdirección:
                            <v-select :options="subs" v-model="instrumento.subdireccion" :reduce="nombre => nombre.id" label="nombre" placeholder="Subdirección / Gerencia" class="mb-2" :clearable=false></v-select>
                        </div>
                    </div>
                    <p>
                    <p>
                    <div class="row">
                        <div class="form-group col-md-">
                            <b-button variant="primary" class ="popup-close" @click="terminarOperacion()">Aceptar</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup" v-if="popUpTrigger2">
                <div class="popup-inner">
                    <div class="boton_cierre">
                        <button type="button" aria-label="Close" class="close" id="minorfx" @click="cancelarOperacion2()">×</button>
                    </div>
                    <div class="row" id="espacio_modal">
                        <div class="form-group col-md-12">
                            Subdirección:
                            <h3><b>{{subdir.nombre}}</b></h3>
                        </div>
                        <div class="form-group col-md-12">
                            Agencia:
                            <v-select :options="agencias" v-model="subdir.agencia" :reduce="nombre => nombre.id" label="nombre" placeholder="Subdirección / Gerencia" class="mb-2" :clearable=false></v-select>
                        </div>
                    </div>
                    <p>
                    <p>
                    <div class="row">
                        <div class="form-group col-md-">
                            <b-button variant="primary" class ="popup-close" @click="terminarOperacion2()">Aceptar</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'

// const Agencia = resource('/api/api/crear_agencia', axios)
const Agencias = resource('/api/api/todas_las_agencias', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

Vue.component('custom-li', {
    props: ['visible'],
    template: '<li v-if="visible"><slot/></li>'
})

Vue.component('custom-div', {
    props: ['visible'],
    template: '<div v-if="visible"><slot/></div>'
})

export default {
    name: 'ListarAgencia',
    components: {
        Breadcrumb
    },
    data () {
        return {
            agencias: [],
            currentPage: 1,
            perPage: 3,
            rows: null,
            page: null,
            show: true,
            edit: false,
            instrumento: null,
            subdir: null,
            alterOrder: true,
            popUpTrigger: false,
            popUpTrigger2: false,
            subs: [],
            isSuperAdmin: false,
            usuario: null
        }
    },
    methods: {
        getData () {
            Agencias.query({}).then((res) => {
                this.show = false
                this.agencias = res.data
                this.rows = res.data.length
                // console.log(this.agencias)
                this.agencias.forEach(element => {
                    element.subdirecciones.forEach(el => {
                        this.subs.push({ id: el.id, nombre: el.nombre })
                    })
                })
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
            // console.log(this.subs)
        },
        updateAgencia (agencia) {
            this.show = true
            axios({
                method: 'put',
                url: '/api/api/crear_agencia/' + agencia.id + '/',
                data: agencia
            })
                .then((response) => {
                    this.subs = []
                    this.getData()
                    this.editar = false
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Agencia actualizada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la Agencia'
                    })
                    console.log(response)
                })
        },
        disableAgencia (agencia) {
            agencia.activo = !agencia.activo
            this.updateAgencia(agencia)
        },
        updateInstrumento (instrumento) {
            axios({
                method: 'put',
                url: '/api/api/crear_instrumento/' + instrumento.id + '/',
                data: instrumento
            })
                .then((response) => {
                    this.show = false
                    this.subs = []
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Instrumento guardado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar la Instrumento'
                    })
                    console.log(response)
                })
        },
        updateSubdireccion (subdireccion) {
            this.show = true
            axios({
                method: 'put',
                url: '/api/api/crear_subdireccion/' + subdireccion.id + '/',
                data: subdireccion
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Subdirección guardada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar la Subdirección'
                    })
                    console.log(response)
                })
        },
        Mover (inst) {
            // console.log(inst)
            this.instrumento = inst
            this.popUpTrigger = true
        },
        MoverSub (sub) {
            // console.log(inst)
            this.subdir = sub
            this.popUpTrigger2 = true
        },
        terminarOperacion () {
            this.updateInstrumento(this.instrumento)
            this.popUpTrigger = false
        },
        terminarOperacion2 () {
            this.updateSubdireccion(this.subdir)
            this.popUpTrigger2 = false
        },
        cancelarOperacion () {
            this.instrumento = null
            this.popUpTrigger = false
        },
        cancelarOperacion2 () {
            this.subdir = null
            this.popUpTrigger2 = false
        },
        SettingsMode () {
            this.alterOrder = !this.alterOrder
        }
    },
    mounted () {
        this.alterOrder = false
        this.instrumento = null
        this.subdir = null
        this.subs = []
        this.getData()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.is_superuser || this.usuario.is_staff) {
                this.isSuperAdmin = true
            }
        })
            .catch((error) => {
                console.log(error)
                this.show = false
            })
    },
    computed: {
        agenciasQuery () {
            const startIndex = this.perPage * (this.currentPage - 1)
            const endIndex = startIndex + this.perPage
            return this.agencias.slice(startIndex, endIndex)
        }
    }
}
</script>

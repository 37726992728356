<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Vista Previa de Ficha" nombreAnterior="Fichas" urlAnterior="/listar_fichas"></Breadcrumb>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section__container">
                            <div class="card card--section" v-if="ficha">
                                <div class="card-header">
                                    <h2 class="card-title">{{ficha.nombre | uppercase}}</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row" v-for="seccion in ficha.secciones" v-bind:key="seccion.id">
                                        <div class="col-md-12">
                                            <h3>{{seccion.format_nombre}}</h3>
                                            <div class="row" v-for="subseccion in seccion.subsecciones" v-bind:key="subseccion.id">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h4>{{subseccion.format_nombre}}</h4>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <p v-html="subseccion.descripcion" v-b-tooltip.hover title="Descripción"></p>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <i><p v-html="subseccion.notas" style="font-size:12px;" v-b-tooltip.hover title="Notas adicionales"></p></i>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="subseccion.elementos.length > 0">
                                                        <div class="col-md-12" v-for="elemento in subseccion.elementos" v-bind:key="elemento.id">
                                                            <div>
                                                                <span v-if="elemento.elemento.type !=='table'">
                                                                    <FormulateInput :type="elemento.elemento.type" :validation="elemento.elemento.validation" :options="elemento.elemento.options" v-model="elemento.elemento.content" />
                                                                </span>
                                                                <span v-if="elemento.elemento.type ==='table'">
                                                                    <ckeditor
                                                                        :editor="editor"
                                                                        v-model="elemento.elemento.content"
                                                                        :config="editorConfig"
                                                                    />
                                                                </span>
                                                                <br>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Breadcrumb from '@/components/Breadcrumb.vue'

const Ficha = resource('/ficha_instrumentos/api/crear_ficha', axios)

export default {
    name: 'PreviewFicha',
    components: {
        ckeditor: CKEditor.component,
        Breadcrumb
    },
    data () {
        return {
            ficha: [],
            routeParams: this.$route.params.id,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: ['bold', 'italic', '|', 'undo', 'redo'],
                language: 'es'
            },
            show: true
        }
    },
    methods: {
        getData () {
            Ficha.query({ id: this.routeParams }).then((res) => {
                this.ficha = res.data.items[0]
                this.show = false
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
        }
    },
    mounted () {
        this.getData()
    }
}
</script>

<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <div class="container-fluid">
            <div class="row">
            <div class="col-md-8">
        <Breadcrumb nombreActual="Gestor Monitoreo" nombreAnterior="Monitoreos" urlAnterior="/listar_monitoreos"></Breadcrumb>
            </div>
            <div class="col-md-4" v-if="monitoreo">
                <div class="mt-4 float-right" v-if="monitoreo">
                    <a class="btn btn-secondary mr-2" v-if="monitoreo.tipo_monitoreo === 'Procesos'" @click="$bvModal.show('modal-duplicar');selectedYear=null;"><font-awesome-icon :icon="['fas', 'copy']" /> Duplicar</a>
                    <a class="btn btn-danger" @click="deleteAlert(monitoreo)" v-if="monitoreo.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'trash-alt']" /> Eliminar</a>
                </div>
            </div>
        </div>
        </div>
        <b-modal id="modal-duplicar" hide-footer no-close-on-backdrop>
            <template #modal-title>
                ¿Deseas duplicar este Monitoreo?
            </template>
            <div class="d-block text-center mb-3">
                <h3>Ingresa el año para el nuevo Monitoreo</h3>
                <v-select :options="years" v-model="selectedYear" label="year" placeholder="Seleccionar"></v-select>
            </div>
            <div class="btns-options">
                <div class="row col-md-12">
                    <b-button class="btn btn-secondary col-md-11" :disabled="!selectedYear" block @click="duplicarMonitoreo(selectedYear, 'duplicar')">Duplicar monitoreo con las respuestas asociada</b-button><a href="#" v-b-tooltip.hover title="Duplicar con las planillas cargadas por las agencias" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                </div>
                <br>
                <div class="row col-md-12">
                    <b-button class="btn btn-primary col-md-11" :disabled="!selectedYear" block @click="duplicarMonitoreo(selectedYear, 'copiar')">Duplicar monitoreo sin las respuestas</b-button><a href="#" v-b-tooltip.hover title="Duplicar sin las planillas cargadas de las agencias" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                </div>
                <br>
                <div class="row col-md-12">
                    <b-button class="btn btn--lightgrey" block @click="$bvModal.hide('modal-duplicar')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-cargar-planillas" size="lg" title="Cargar Planillas">
            <div class="row">
                <div class="form-group col-md-10">
                    <label>Planillas*</label>
                    <b-form-file multiple :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los documentos" placeholder="No hay documentos seleccionados" ref="files">
                       <template slot="file-name" slot-scope="{ names }">
                             <b-badge variant="light">{{ names[0] }}</b-badge>
                             <b-badge v-if="names.length > 1" variant="light" class="ml-1">
                               + {{ names.length - 1 }}
                             </b-badge>
                       </template>
                    </b-form-file>
                </div>
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModal()">
                    Cancelar
                </b-button>
                <b-button @click="uploadFile()" class="btn btn-primary" :disabled="files.length===0">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></b-button>
            </template>
        </b-modal>
        <b-modal id="modal-tipo-indicador" size="md" title="Seleccionar Tipo de Indicador">
            <div class="row">
                <div class="form-group col-md-10">
<!--                     <span class="col-md-2" v-for="tipoindicador in tipoIndicadores" v-bind:key="tipoindicador.id" :value="tipoindicador">
                        <ModalIndicador :modal="'modal-create-indicador-' + tipoindicador" :monitoreo="monitoreo.id" :instrumento="selectedInstrumento" :tipoIndicador="tipoindicador.id" :tipoIndicadorNombre="tipoindicador.nombre" :editar="false" :formIndicador="{}" @accept="getData"></ModalIndicador>
                    </span> -->
                    <v-select :options="tipoIndicadores" v-model="form.tipo_indicador" label="nombre" placeholder="Seleccionar"></v-select>
                </div>
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModalTipoIndicador()">
                    <font-awesome-icon :icon="['fas', 'times']" />
                </b-button>
                <ModalIndicador v-if="changed" modal="modal-create-indicador" :monitoreo="monitoreo.id" :instrumento="selectedInstrumento" :tipoIndicador="changed.id" :tipoIndicadorNombre="changed.nombre" :editar="false" :formIndicador="{}" @accept="getData();closeModalTipoIndicador()"></ModalIndicador>
            </template>
        </b-modal>
        <section class="ficha ficha--crear" v-if="!routeParams">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <div class="section__container">
                            <div class="card card--section card--crear">
                                <div class="card-body">
                                    <h2 class="card-title">Ingresar datos del Monitoreo</h2>
                                    <div class="form-group">
                                        <label>Nombre*<a href="#" v-b-tooltip.hover title="Nombre que recibe el Monitoreo" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <input type="text" name="" class="form-control" v-model="formMonitoreo.nombre">
                                    </div>
                                    <div class="form-group">
                                        <label>Descripción<a href="#" v-b-tooltip.hover title="Descripción de Monitoreo en caso de que aplique" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <textarea type="text" name="" class="form-control" v-model="formMonitoreo.descripcion"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Año del Monitoreo* <a href="#" v-b-tooltip.hover title="Año de evaluación del Monitoreo"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <select v-model="formMonitoreo.anio" class="form-control">
                                            <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Tipo de Monitoreo*<a href="#" v-b-tooltip.hover title="Tipo de Monitoreo a crear" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <select  v-model="formMonitoreo.tipo_monitoreo" class="form-control">
                                            <option v-for="tipo in tiposmonitoreo" v-bind:key="tipo" :value="tipo">
                                                {{tipo}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="btns btns--right">
                                        <a @click="createMonitoreo()" class="btn btn-primary">Guardar y Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="ficha ficha--crear-secciones" v-if="routeParams && monitoreo">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body">
                                <div class="section__container">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h2 class="card-title">Monitoreo de {{monitoreo.tipo_monitoreo}}: {{ monitoreo.nombre }} - {{monitoreo.anio}} </h2>
                                            <p style="text-align: justify;">{{monitoreo.descripcion}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <a @click="editar=true;" class="btn btn-secondary float-right" title="Editar información del Monitoreo" v-show="monitoreo.estado==='Por Publicar'"><font-awesome-icon :icon="['fas', 'edit']" /></a>
                                        </div>
                                    </div>
                                    <!--EDIT-->
                                    <div class="form-group" v-if="editar">
                                        <label>Nombre*</label>
                                        <input type="text" name="" class="form-control" v-model="monitoreo.nombre">
                                        <label>Descripción*</label>
                                        <textarea type="text" name="" class="form-control" v-model="monitoreo.descripcion"></textarea>
                                        <label>Tipo de Monitoreo*</label>
                                            <select v-model="monitoreo.tipo_monitoreo" class="form-control">
                                                <option v-for="tipo in tiposmonitoreo" v-bind:key="tipo" :value="tipo">
                                                    {{tipo}}
                                                </option>
                                        </select>
                                    </div>
                                    <div class="btns btns--right">
                                        <a v-if="editar" @click="editar=false;" class="btn btn-secondary"><font-awesome-icon :icon="['fas', 'times']" /> Cancelar</a>
                                        <a v-if="editar" @click="updateMonitoreo(monitoreo)" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                    </div>
                                    <!--FIN EDIT-->
                                </div>
                            </div>
                        </div>
                        <div class="section__container" v-if="monitoreo.tipo_monitoreo === 'Procesos'">
                            <div>
                                <b-card no-body class="mb-1">
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h2 class="card-title">Planillas <a href="#" v-b-tooltip.hover title="Planillas base para ser completadas por las agencias" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></h2>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span  v-if="monitoreo.planillas.length===0" class="badge bg-alert"> No existen Planillas cargadas</span>
                                                <ul class="files-list">
                                                    <li v-for="planilla in monitoreo.planillas" v-bind:key="planilla.id">
                                                        <a class="file-name" :href="baseUlr + planilla.documento__url" target="_blank" download>
                                                            <font-awesome-icon :icon="['fas', 'file']" />
                                                            <span class="file-name__text">
                                                                {{ planilla.nombre }}
                                                                <br>
                                                                <i><strong>{{planilla.fechahora | dateTimeFormat}}</strong></i>
                                                            </span>
                                                        </a>
                                                        <a @click="alertaPlanilla(planilla);" class="delete-file" v-if="monitoreo.estado!=='Publicada'">
                                                            <font-awesome-icon :icon="['fas', 'trash-alt']" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </div>
                        </div>
                        <div class="section__container" v-if="monitoreo.tipo_monitoreo === 'Productos y Resultados'">
                            <div>
                                <b-card no-body class="mb-1">
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h2 class="card-title">Indicadores por Instrumento</h2>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                Filtrar por:
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <v-select :options="listAgencia" v-model="formSearch.agencia" label="nombre" :reduce="nombre => nombre.id" placeholder="Agencia"></v-select>
                                            </div>
                                            <div class="col-md-3">
                                                <v-select :options="listSubdireccion" v-model="formSearch.subdireccion" label="nombre" :reduce="nombre => nombre.id" placeholder="Subdirección / Gerencia">
                                                    <span slot="no-options">Seleccione una Agencia Primero.</span>
                                                </v-select>
                                            </div>
                                            <div class="col-md-3">
                                                <v-select :options="listInstrumento" v-model="formSearch.instrumento" label="nombre" :reduce="nombre => nombre.id" placeholder="Instrumento">
                                                    <span slot="no-options">Seleccione una Subdirección/Gerencia Primero.</span>
                                                </v-select>
                                            </div>
                                            <div class="col-md-3">
                                                <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año en el que se reportó el Indicador"></v-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-stripped table-bordered" id="tabla-instrumentos" v-if="instrumentosindicadores">
                                                    <thead>
                                                    </thead>
                                                    <tbody v-for="instrumento in instrumentosindicadores" v-bind:key="instrumento.id" class="row__instrumento-container">
                                                        <tr class="row__instrumento">
                                                            <td class="instrumento">{{instrumento.nombre}} - {{instrumento.subdireccion__nombre}} - {{instrumento.agencia__nombre}}</td>
                                                            <td>
                                                                <!-- <input class="form-check-input" type="checkbox" :value="instrumento.id" v-model="monitoreo.instrumentos" @click="selectIndicadores(monitoreo, instrumento)" :disabled="monitoreo.estado==='Publicada'"> -->
                                                                <div class="checkbox checkbox--bold">
                                                                    <label>
                                                                        <input type="checkbox" :value="instrumento.id" v-model="monitoreo.instrumentos" @click="selectIndicadores(monitoreo, instrumento)">
                                                                            <span class="cr"><font-awesome-icon :icon="['fas', 'check']" /></span>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a v-show="monitoreo.estado==='Por Publicar'" @click="openModalTipoIndicador(instrumento.id)" class="btn btn-secondary">
                                                                    <font-awesome-icon :icon="['fas', 'file']" /> Crear Indicador
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="instrumento.indicadores.length === 0">
                                                            <td colspan="3"><small v-if="formSearch.anio" class="badge bg-alert">No existen Indicadores para el año seleccionado en este Instrumento</small>
                                                            <small v-else class="badge bg-alert">No existen Indicadores para este Instrumento</small></td>
                                                        </tr>
                                                        <tr v-for="indicador in instrumento.indicadores" v-bind:key="indicador.id" class="row__indicador">
                                                            <td>{{indicador.nombre}} - <span class="badge bg-badge bg-success">{{indicador.tipo_indicador__nombre}}</span><span class="badge bg-badge">Año de reporte: {{indicador.anio}}</span></td>
                                                            <td>
                                                                <!-- <input class="form-check-input" type="checkbox" :value="indicador.id" v-model="indicador.activo_monitoreo" @click="selectIndicador(indicador, monitoreo, instrumento)" :disabled="!includesInstrumento(instrumento.id) || monitoreo.estado==='Publicada'"> -->
                                                                <div class="checkbox">
                                                                    <label>
                                                                    <span v-if="includesInstrumento(instrumento.id) && !tieneMonitoreo(indicador, instrumento.id)" @click="selectIndicador(indicador, monitoreo, instrumento, false)">
                                                                        <span class="cr"></span>
                                                                    </span>
                                                                    <span v-if="includesInstrumento(instrumento.id) && tieneMonitoreo(indicador, instrumento.id)" @click="selectIndicador(indicador, monitoreo, instrumento, true)">
                                                                        <span class="cr"><font-awesome-icon :icon="['fas', 'check']" /></span>
                                                                    </span>
<!--                                                                         <input type="checkbox" :value="indicador.id" v-model="indicador.activo_monitoreo" @click="selectIndicador(indicador, monitoreo, instrumento)" :disabled="!includesInstrumento(instrumento.id) || monitoreo.estado==='Publicada'">
                                                                            <span class="cr"><font-awesome-icon :icon="['fas', 'check']" /></span> -->
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <ModalIndicador v-show="monitoreo.estado==='Por Publicar'" v-if="monitoreo.instrumentos" :modal="'modal-update-indicador' + indicador.id + '-' + indicador.tipo_indicador" :monitoreo="monitoreo.id" :instrumento="instrumento.id" :tipoIndicador="indicador.tipoindicador" :tipoIndicadorNombre="indicador.tipo_indicador.tipo_indicador__nombre" :editar="true" :formIndicador="indicador" @accept="getData"></ModalIndicador>
                                                                <a @click="deleteIndicadorAlert(indicador)" role="button" v-if="!indicador.ficha" class="btn btn-danger btn-sm"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                  <b-pagination
                                                  v-model="currentPage"
                                                  :total-rows="rows"
                                                  :per-page="perPage"
                                                  aria-controls="tabla-instrumentos"
                                                  ></b-pagination>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </div>
                        </div>
                        <div class="card card--button" v-show="monitoreo.estado==='Por Publicar'" v-if="monitoreo.tipo_monitoreo === 'Procesos'">
                            <a @click="openModal()" class="card--button__btn card--button__btn--lightgrey">
                                <font-awesome-icon :icon="['fas', 'file']" />
                                <span class="card--button__text">Cargar planillas</span>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="section-elements">
                                    <h2 class="card-title">Información adicional</h2>
                                    <p class="mb-3">Estado del Monitoreo: <span class="badge bg-success">{{monitoreo.estado}}</span></p>
                                    <div class="mb-3" v-if="monitoreo.tipo_monitoreo === 'Procesos'">
                                        <label>Agencias Asociadas<a href="#" v-b-tooltip.hover title="Agencias que completarán el Monitoreo" class="col-md-1"><font-awesome-icon :icon="['fas', 'info-circle']" /></a></label>
                                        <v-select multiple :options="listAgencia" v-model="monitoreo.agencias" label="nombre" :reduce="nombre => nombre.id"></v-select>
                                    </div>
                                    <div class="btns btns--right">
                                        <a class="btn btn-primary" @click="monitoreo.estado='Publicada';updateMonitoreo(monitoreo);" v-if="monitoreo.estado=='Por Publicar'"><font-awesome-icon :icon="['fas', 'check']" /> Publicar Monitoreo</a><a v-if="monitoreo.estado=='Por Publicar'" href="#" v-b-tooltip.hover title="Al publicar el monitoreo, quedará visible para las agencias"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                        <a class="btn btn--lightgrey" @click="monitoreo.estado='Por Publicar';updateMonitoreo(monitoreo);" v-if="monitoreo.estado=='Publicada'"><font-awesome-icon :icon="['fas', 'file']" /> Marcar como Borrador</a><a v-if="monitoreo.estado=='Publicada'" href="#" v-b-tooltip.hover title="Al marcar el monitoreo como borrador, las agencias no podrán visualizarlo"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                                        <a class="btn btn-primary" @click="alertaAgencias(monitoreo);" v-if="monitoreo.estado=='Publicada' && monitoreo.tipo_monitoreo==='Procesos'"><font-awesome-icon :icon="['fas', 'sync-alt']" /> Actualizar Agencias</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import router from '../router/index.js'
import Breadcrumb from '@/components/Breadcrumb.vue'
import ModalIndicador from '@/components/ModalIndicador.vue'

const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
const Monitoreo = resource('/monitoreo/api/crear_monitoreo', axios)
const Instrumento = resource('/ficha_instrumentos/api/asignar_instrumento', axios)
const InstrumentoIndicador = resource('/monitoreo/api/listar_instrumentos_indicadores', axios)
const TipoIndicador = resource('/indicadores/api/listar_tipo_indicador', axios)
const IndicadorAnio = resource('/monitoreo/api/ver_anio_indicadores', axios)
const ListarAgencia = resource('/api/api/listar_agencia', axios)
const ListarSubdireccion = resource('/api/api/listar_subdireccion', axios)
const ListarInstrumento = resource('/api/api/listar_instrumento', axios)

export default {
    name: 'CrearMonitoreo',
    components: { Breadcrumb, ModalIndicador },
    data () {
        return {
            usuario: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: null,
            tiposmonitoreo: ['Procesos', 'Productos y Resultados'],
            monitoreo: null,
            editar: false,
            formMonitoreo: {},
            routeParams: this.$route.params.id,
            instrumentos: [],
            instrumentosindicadores: [],
            files: [],
            acceptedFiles: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/x-rar-compressed,application/zip,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg',
            tipoIndicadores: [],
            form: {},
            selectedInstrumento: null,
            changed: null,
            anios: [],
            formSearch: { agencia: null, subdireccion: null, instrumento: null, anio: null },
            listAgencia: [],
            listSubdireccion: [],
            listInstrumento: [],
            selectedYear: null,
            baseUlr: process.env.VUE_APP_BASEURL,
            show: false
        }
    },
    methods: {
        getListAgencia () {
            ListarAgencia.query({ }).then((res) => {
                this.listAgencia = res.data
            })
        },
        getListSubdireccion () {
            ListarSubdireccion.query({ agencia: this.formSearch.agencia }).then((res) => {
                this.listSubdireccion = res.data
            })
        },
        getListInstrumento () {
            ListarInstrumento.query({ subdireccion__agencia: this.formSearch.agencia, subdireccion: this.formSearch.subdireccion }).then((res) => {
                this.listInstrumento = res.data
            })
        },
        getInstrumentoIndicador () {
            Instrumento.query({ }).then((res) => {
                this.instrumentos = res.data
            })
            InstrumentoIndicador.query({ page: this.currentPage, per_page: 10, anio: this.formSearch.anio, agencia: this.formSearch.agencia, subdireccion: this.formSearch.subdireccion, instrumento: this.formSearch.instrumento }).then((res) => {
                this.instrumentosindicadores = res.data.items
                this.perPage = res.data.paginate.per_page
                this.rows = res.data.paginate.count
            })
        },
        getData () {
            UsuarioActual.query({}).then((res) => {
                this.usuario = res.data[0]
            })
            if (this.routeParams) {
                this.show = true
                Monitoreo.query({ id: this.routeParams }).then((res) => {
                    this.show = false
                    this.monitoreo = res.data.items[0]
                })
                    .catch((error) => {
                        console.log(error)
                        this.show = false
                    })
                IndicadorAnio.query({}).then((res) => {
                    this.anios = res.data
                })
                this.getInstrumentoIndicador()
            }
            this.closeModalTipoIndicador()
        },
        createMonitoreo () {
            this.show = true
            axios({
                method: 'post',
                url: '/monitoreo/api/crear_monitoreo/',
                data: this.formMonitoreo
            })
                .then((response) => {
                    this.show = false
                    router.push({ name: 'CrearPlanillas', params: { id: response.data.id } })
                    location.reload()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Monitoreo creado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    console.log(response)
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el Monitoreo'
                    })
                })
        },
        updateMonitoreo (monitoreo) {
            this.show = true
            axios({
                method: 'put',
                url: '/monitoreo/api/crear_monitoreo/' + monitoreo.id + '/',
                data: monitoreo
            })
                .then((response) => {
                    this.getData()
                    this.editar = false
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Monitoreo actualizado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el Monitoreo'
                    })
                    console.log(response)
                })
        },
        deleteMonitoreo (monitoreo) {
            this.show = true
            axios({
                method: 'delete',
                url: '/monitoreo/api/crear_monitoreo/' + monitoreo.id + '/'
            })
                .then((response) => {
                    this.show = false
                    router.push({ name: 'ListarMonitoreos' })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Monitoreo'
                    })
                    console.log(response)
                })
        },
        duplicarMonitoreo (anio, accion) {
            var url = ''
            var formCopy = {}
            formCopy.monitoreoId = this.monitoreo.id
            formCopy.anio = anio
            if (accion === 'duplicar') {
                url = '/monitoreo/api/crear_monitoreo/' + this.monitoreo.id + '/duplicar/'
            } else if (accion === 'copiar') {
                url = '/monitoreo/api/crear_monitoreo/' + this.monitoreo.id + '/duplicar_vacio/'
            }
            this.show = true
            axios({
                method: 'put',
                url: url,
                data: formCopy
            })
                .then((response) => {
                    this.show = false
                    this.$bvModal.hide('modal-duplicar')
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Monitoreo copiado exitosamente'
                    })
                    const id = JSON.parse(response.data)[0].pk
                    this.$router.push({ path: `/crear_monitoreo/${id}` })
                    location.reload()
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al copiar el monitoreo'
                    })
                    console.log(response)
                })
        },
        alertaAgencias (monitoreo) {
            this.$swal({
                title: 'Vas a actualizar las Agencias, ¿estás seguro?',
                text: 'Si eliminaste una agencia y ya existen cambios realizados, se perderán.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.updateMonitoreo(monitoreo)
                }
            })
        },
        openModal () {
            this.$root.$emit('bv::show::modal', 'modal-cargar-planillas')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-cargar-planillas')
        },
        uploadFile () {
            var formData = new FormData()
            for (var i = 0; i < this.files.length; i++) {
                var file = this.files[i]
                formData.append('documento[' + i + ']', file)
            }
            formData.append('monitoreo', this.monitoreo.id)
            this.show = true
            axios.post('/monitoreo/api/cargar_planilla_monitoreo/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.closeModal()
                    this.files = []
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Planillas cargados exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al cargar los Planillas'
                    })
                })
        },
        deleteFile (planilla) {
            this.show = true
            axios({
                method: 'delete',
                url: '/monitoreo/api/cargar_planilla_monitoreo/' + planilla.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Planilla eliminada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Planilla'
                    })
                })
        },
        alertaPlanilla (planilla) {
            this.$swal({
                title: 'Vas a eliminar esta planilla, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFile(planilla)
                }
            })
        },
        selectIndicadores (monitoreo, instrumento) {
            if (monitoreo.instrumentos.includes(instrumento.id)) {
                var index = monitoreo.instrumentos.indexOf(instrumento.id)
                monitoreo.instrumentos.splice(index, 1)
            } else {
                monitoreo.instrumentos.push(instrumento.id)
            }
            // console.log(monitoreo)
            this.updateMonitoreo(monitoreo)
        },
        selectIndicador (indicador, monitoreo, instrumento, drop) {
            indicador.monitoreo = monitoreo.id
            indicador.instrumento = instrumento.id
            indicador.delete = drop
            this.show = true
            axios({
                method: 'put',
                url: '/monitoreo/api/crear_indicador_monitoreo/' + indicador.id + '/',
                data: indicador
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Indicador guardado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar el Indicador'
                    })
                })
        },
        includesInstrumento (instrumento) {
            if (this.monitoreo.instrumentos.includes(instrumento)) return true
            return false
        },
        openModalTipoIndicador (instrumento) {
            this.selectedInstrumento = instrumento
            this.$root.$emit('bv::show::modal', 'modal-tipo-indicador')
        },
        closeModalTipoIndicador () {
            this.$root.$emit('bv::hide::modal', 'modal-tipo-indicador')
        },
        tieneMonitoreo (indicador, instrumento) {
            var temp = false
            if (indicador.monitoreo_instrumento.length > 0) {
                indicador.monitoreo_instrumento.filter((item) => {
                    if (item.instrumento === instrumento && item.monitoreo === Number(this.routeParams)) {
                        temp = true
                    }
                })
            }
            return temp
        },
        deleteAlert (monitoreo) {
            this.$swal({
                title: 'Vas a eliminar el monitoreo',
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteMonitoreo(monitoreo)
                }
            })
        },
        deleteIndicadorAlert (indicador) {
            this.$swal({
                title: 'Vas a eliminar el Indicador',
                text: '¿Estás seguro? Se perderán todos los datos',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteIndicador(indicador)
                }
            })
        },
        deleteIndicador (indicador) {
            this.show = true
            axios({
                method: 'delete',
                url: '/monitoreo/api/crear_indicador_monitoreo/' + indicador.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Indicador eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Indicador'
                    })
                })
        }
    },
    mounted () {
        this.getData()
        this.getListAgencia()
        // this.getListSubdireccion()
        // this.getListInstrumento()
        this.$watch('currentPage', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getInstrumentoIndicador()
            }
        }, { immediate: true })
        this.$watch('form.tipo_indicador', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.changed = newLocale
            }
        }, { immediate: true })
        TipoIndicador.query({}).then((res) => {
            this.tipoIndicadores = res.data
        }, function (error) {
            console.log(error)
        })
    },
    computed: {
        years () {
            const year = new Date().getFullYear()
            var temp = Array.from({ length: year - 2005 }, (value, index) => 2011 + index)
            // var index = ''
            // if (this.fichasAnios.data) {
            //     this.fichasAnios.data.forEach(anio => {
            //         index = temp.indexOf(anio)
            //         temp.splice(index, 1)
            //     })
            // }
            return temp
        }
    },
    watch: {
        'formSearch.anio': {
            deep: true,
            handler: function (newVal) {
                this.getInstrumentoIndicador()
            }
        },
        'formSearch.agencia': {
            deep: true,
            handler: function (newVal) {
                if (newVal !== null) {
                    this.formSearch.subdireccion = null
                    this.getListSubdireccion()
                    this.getListInstrumento()
                    // this.getInstrumentoIndicador()
                } else {
                    this.listSubdireccion = []
                    this.listInstrumento = []
                    this.formSearch.subdireccion = null
                    this.formSearch.instrumento = null
                }
                this.getInstrumentoIndicador()
            }
        },
        'formSearch.subdireccion': {
            deep: true,
            handler: function (newVal) {
                if (newVal !== null) {
                    this.formSearch.instrumento = null
                    this.getListInstrumento()
                    // this.getInstrumentoIndicador()
                } else {
                    this.listInstrumento = []
                    this.formSearch.instrumento = null
                }
                // this.getData()
                this.getInstrumentoIndicador()
            }
        },
        'formSearch.instrumento': {
            deep: true,
            handler: function (newVal) {
                this.getInstrumentoIndicador()
            }
        }
    }
}
</script>

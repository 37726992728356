<template>
    <div>
        <Breadcrumb nombreActual='Panel de Administración'></Breadcrumb>
        <section class="ficha">
            <div class="container3">
                <div class="row align-items-center">
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/listar_fichas" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'th-list']" />
                                <span class="card--button__text">Gestor Ficha</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/listar_monitoreos" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'chart-bar']" />
                                <span class="card--button__text">Gestor Monitoreo</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/listar_agencias" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'user-tie']" />
                                <span class="card--button__text">Gestor Agencias</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/listar_usuarios" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'users']" />
                                <span class="card--button__text">Gestor Usuarios</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/crear_tipo_indicador" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'font']" />
                                <span class="card--button__text">Gestor Tipo Indicador</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/crear_unidad_medida" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'square-root-alt']" />
                                <span class="card--button__text">Gestor Unidad de Medida</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/reportes" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'file']" />
                                <span class="card--button__text">Reportes</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card card--button">
                            <router-link to="/" class="card--button__btn">
                                <font-awesome-icon :icon="['fas', 'home']" />
                                <span class="card--button__text">Volver al Inicio</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    name: 'PanelAdministracion',
    components: {
        Breadcrumb
    },
    async beforeCreate () {

    },
    data () {
        return {
            usuario: {}
        }
    },
    methods: {
    },
    mounted () {
    }
}
</script>

<template>
    <div>
        <Breadcrumb nombreActual="Reportes" nombreAnterior="Panel Administración" urlAnterior="/panel_administracion"></Breadcrumb>
        <section class="ficha">
            <div class="container2">
                <div class="row justify-content-center">
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Reporte
                            </div>
                            <div class="card-r-subtitle">
                                Monitoreo de Productos y Resultados
                            </div>
                            <div class="card-r-text">
                                Planilla Excel que resume la información y datos reportados por las Agencias respecto a los produstos y resultados de la cadena causal de los instrumentos.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" :href="urlDescargaDesempeno" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar XLS Monitoreo Productos y Resultados</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Reporte
                            </div>
                            <div class="card-r-subtitle">
                                Monitoreo de Procesos
                            </div>
                            <div class="card-r-text">
                                Planilla Excel que resume la información y datos reportados por las Agencias respecto a los procesos de la implementación de los instrumentos.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" :href="urlDescargaProceso" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar XLS Monitoreo Procesos</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Reporte
                            </div>
                            <div class="card-r-subtitle">
                                Resumen de Fichas
                            </div>
                            <div class="card-r-text">
                                Planilla Excel que presenta el estado de avance o completitud de las fichas de diseño y evaluación de los instumentos.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" :href="urlDescargaResumenFichas" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar XLS Resumen Fichas</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    name: 'Reportes',
    components: {
        Breadcrumb
    },
    async beforeCreate () {

    },
    data () {
        return {
        }
    },
    methods: {
    },
    mounted () {
    },
    computed: {
        urlDescargaDesempeno () {
            return process.env.VUE_APP_BASEURL + '/monitoreo/api/xls_monitoreo_desempeno'
        },
        urlDescargaProceso () {
            return process.env.VUE_APP_BASEURL + '/monitoreo/api/xls_monitoreo_procesos'
        },
        urlDescargaResumenFichas () {
            return process.env.VUE_APP_BASEURL + '/ficha_instrumentos/api/xls_resumen_fichas'
        }
    }
}
</script>

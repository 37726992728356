<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Gestor de Fichas" nombreAnterior="Panel de Administración" urlAnterior="/panel_administracion"></Breadcrumb>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="section__container">
                            <div class="card card--section">
                                <div class="card-header">
                                    <h2 class="card-title d-inline-block">Gestor de Fichas</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            Filtrar por:
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <v-select :options="tipos" v-model="formSearch.tipo" label="tipo" placeholder="Tipo de Ficha" class="mb-2"></v-select>
                                        </div>
                                        <div class="col-md-2">
                                            <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año de ficha" class="mb-2"></v-select>
                                        </div>
                                        <div class="col-md-5">
                                            <input class="form-control" id="styled-search" type="text" v-model="searchQuery" placeholder="Nombre de Ficha" />
                                        </div>
                                        <div class="col-md-2" id="center_btn2">
                                            <router-link to="/crear_ficha" class="btn btn-primary">
                                                <font-awesome-icon :icon="['fas', 'plus']" /> Crear ficha
                                            </router-link>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="alert alert-info" role="alert" v-if="fichas.length === 0">
                                        No hay información disponible
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-stripped table-hover table-bordered" id="tabla-fichas" v-if="fichas">
                                            <thead>
                                                <tr>
                                                    <td>Nombre</td>
                                                    <td>Año</td>
                                                    <td>Tipo</td>
                                                    <td>Secciones</td>
                                                    <td>Estado</td>
                                                    <td>Fecha Creación</td>
                                                    <td>Última Actualización</td>
                                                    <td>Acción</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="fichas.length === 0">
                                                    <small class="badge bg-alert">No existen Fichas</small>
                                                </tr>
                                                <tr v-for="ficha in resultQuery" v-bind:key="ficha.id">
                                                    <td>{{ficha.nombre}}</td>
                                                    <td>{{ficha.anio}}</td>
                                                    <td>{{ficha.tipo_ficha}}</td>
                                                    <td>{{ficha.secciones.length}}</td>
                                                    <td><span class="badge" v-bind:class="{ 'bg-por-validar': ficha.estado ==='Por Publicar', 'bg-validada': ficha.estado === 'Publicada'}">{{ficha.estado}}</span></td>
                                                    <td>{{ficha.fecha_creacion | dateFormat}}</td>
                                                    <td>{{ficha.fecha_actualizacion | dateTimeFormat}}</td>
                                                    <td>
                                                        <div class="btns mt-0">
                                                            <router-link :to="'/preview_ficha/' + ficha.id" class="btn btn-primary btn-sm"><font-awesome-icon :icon="['fas', 'eye']" /> Vista Previa</router-link>
                                                            <router-link :to="'/crear_ficha/' + ficha.id" class="btn btn-secondary btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /> Editar</router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                      <b-pagination
                                      v-model="currentPage"
                                      :total-rows="rows"
                                      :per-page="perPage"
                                      aria-controls="tabla-fichas"
                                      ></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'

// const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
const Ficha = resource('/ficha_instrumentos/api/crear_ficha', axios)
const Ficha2 = resource('/ficha_instrumentos/api/crear_ficha_nopag', axios)
const Anio = resource('/ficha_instrumentos/api/anios_ficha', axios)

export default {
    name: 'ListarFichas',
    components: {
        Breadcrumb
    },
    data () {
        return {
            fichas: [],
            currentPage: 1,
            perPage: 15,
            rows: null,
            original_length: null,
            page: null,
            formSearch: {},
            anios: [],
            tipos: ['Diseño', 'Evaluación'],
            show: true,
            searchQuery: null
        }
    },
    methods: {
        getAnio () {
            Anio.query({ }).then((res) => {
                this.anios = res.data
            })
        },
        getData () {
            Ficha.query({ page: this.currentPage, per_page: 10, tipo_ficha: this.formSearch.tipo, anio: this.formSearch.anio }).then((res) => {
                this.show = false
                this.fichas = res.data.items
                this.perPage = res.data.paginate.per_page
                this.rows = res.data.paginate.count
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
        },
        getData2 () {
            Ficha2.query({ tipo_ficha: this.formSearch.tipo, anio: this.formSearch.anio }).then((res) => {
                this.show = false
                this.fichas = res.data
                this.rows = res.data.length
                this.original_length = res.data.length
            })
                .catch((error) => {
                    console.log(error)
                    this.show = false
                })
        }
    },
    mounted () {
        this.getAnio()
        this.getData2()
        /* this.$watch('currentPage', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true }) */
    },
    computed: {
        resultQuery () {
            if (this.searchQuery) {
                return this.fichas.filter((item) => {
                    return (this.searchQuery.toLowerCase().split(' ').every(v => item.nombre.toLowerCase().includes(v)))
                })
            } else {
                const startIndex = this.perPage * (this.currentPage - 1)
                const endIndex = startIndex + this.perPage
                return this.fichas.slice(startIndex, endIndex)
            }
        }
    },
    watch: {
        searchQuery: {
            deep: true,
            handler: function (newVal) {
                if (newVal.length > 0) {
                    this.rows = this.resultQuery.length
                    this.perPage = this.resultQuery.length
                    // console.log(this.rows)
                } else {
                    this.rows = this.original_length
                    this.perPage = 15
                    // console.log(this.rows)
                }
            }
        },
        'formSearch.anio': {
            deep: true,
            handler: function (newVal) {
                this.getData2()
            }
        },
        'formSearch.tipo': {
            deep: true,
            handler: function (newVal) {
                this.getData2()
            }
        }
    }
}
</script>

<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Gestor de Agencia" nombreAnterior="Agencias" urlAnterior="/listar_agencias"></Breadcrumb>
        <section class="ficha ficha--crear" v-if="!routeParams">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <div class="section__container">
                            <div class="card card--section card--crear">
                                <div class="card-body">
                                    <h3>Ingresar datos de la Agencia</h3>
                                    <span>{{errorNombre[0]}}</span>
                                    <div class="form-group">
                                        <label>Nombre*</label>
                                        <input type="text" name="" class="form-control" v-model="formAgencia.nombre">
                                    </div>
                                    <div class="form-group">
                                        <label>Descripción</label>
                                        <textarea v-model="formAgencia.descripcion" class="form-control"></textarea>
                                    </div>
                                    <div class="btns btns--right">
                                        <a @click="createAgencia" class="btn btn-primary">Guardar y Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="ficha ficha--crear" v-if="routeParams && agencia">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="section__container">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <h2>Agencia: {{ agencia.nombre }}</h2>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="btns btns--right">
                                                <a @click="deleteAlert(agencia)" role="button" v-show="isSuperAdmin" class="btn btn-danger btn-sm"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                <a  v-if="agencia.activo" @click="disableAgencia(agencia)" class="btn btn--alert-disabled">Inhabilitar Agencia <font-awesome-icon :icon="['fas', 'ban']"/></a>
                                                <a  v-if="!agencia.activo" @click="disableAgencia(agencia)" class="btn btn--alert-nondisabled">Habilitar Agencia <font-awesome-icon :icon="['fas', 'circle']"/></a>
                                                <small title="Editar información de la Agencia"><a @click="editar=true;" class="btn btn--lightgrey btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /></a></small>
                                            </div>
                                        </div>
                                    </div>
                                    <!--EDIT-->
                                    <div class="form-group" v-if="editar">
                                        <label>Nombre*</label>
                                        <input type="text" name="" class="form-control" v-model="agencia.nombre">
                                    </div>
                                    <div class="form-group" v-if="editar">
                                        <label>Descripción</label>
                                        <textarea v-model="agencia.descripcion" class="form-control"></textarea>
                                    </div>
                                    <div class="btns btns--right">
                                        <a v-if="editar" @click="editar=false;" id="btn-secundario" class="btn btn-secondary"><font-awesome-icon :icon="['fas', 'times']" /> Cancelar</a>
                                        <a v-if="editar" @click="updateAgencia(agencia)" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                    </div>
                                    <!--FIN EDIT-->
                                </div>
                            </div>
                        </div>
                        <div class="section__container">
                            <div v-for="subdireccion in agencia.subdirecciones" v-bind:key="subdireccion.id">
                                <b-card no-body class="mb-1">
                                        <b-card-body>
                                            <div class="row">
                                                <div class="col-md-7">
                                                    <h3>Subdirección/Gerencia:</h3>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="btns btns--right">
                                                        <a @click="deleteAlertSubdireccion(subdireccion)" role="button" v-show="isSuperAdmin" class="btn btn-danger btn-sm"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                        <a  v-if="subdireccion.activo" @click="disableSubdireccion(subdireccion)" class="btn btn--alert-disabled">Inhabilitar Subdirección/Gerencia <font-awesome-icon :icon="['fas', 'ban']"/></a>
                                                        <a  v-if="!subdireccion.activo" @click="disableSubdireccion(subdireccion)" class="btn btn--alert-nondisabled">Habilitar Subdirección <font-awesome-icon :icon="['fas', 'circle']"/></a>
                                                        <!--<a @click="createInstrumento(subdireccion)" class="btn btn--lightgrey float-right"><font-awesome-icon :icon="['fas', 'plus']" /> Crear Nuevo Instrumento</a>-->
                                                        <a @click="createInstrumento(subdireccion)" class="btn btn--lightgrey"><font-awesome-icon :icon="['fas', 'plus']" /> Crear Nuevo Instrumento</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <!-- Título sección -->
                                                <div class="form-group col-md-6">
                                                    <label>Nombre*</label>
                                                    <input type="text" name="" class="form-control" v-model="subdireccion.nombre">
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label>Descripción</label>
                                                    <textarea v-model="subdireccion.descripcion" class="form-control"></textarea>
                                                </div>
                                            </div>
                                                <div class="btns btns--right">
                                                    <a @click="updateSubdireccion(subdireccion);createInstrumento(subdireccion)" class="btn btn--lightgrey"><font-awesome-icon :icon="['fas', 'indent']" /> Guardar y crear nueva Subdirección</a>
                                                    <a @click="updateSubdireccion(subdireccion)" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                                </div>
                                                <div class="accordion" role="tablist" v-for="instrumento in subdireccion.instrumentos" v-bind:key="instrumento.id">
                                                    <b-card no-body class="card--subsection mb-1">
                                                        <b-card-header header-tag="header" class="p-0" role="tab">
                                                            <b-button block v-b-toggle="'subaccordion-' + instrumento.id" variant="info">Instrumento: {{instrumento.nombre}}</b-button>
                                                        </b-card-header>
                                                        <b-collapse :id="'subaccordion-' + instrumento.id" accordion="my-subaccordion" role="tabpanel">
                                                            <b-card-body>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="btns btns--right">
                                                                            <a @click="deleteAlertInstrumento(instrumento)" role="button" v-show="isSuperAdmin" class="btn btn-danger btn-sm"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                                                            <a  v-if="instrumento.activo" @click="disableInstrumento(instrumento)" class="btn btn--alert-disabled">Inhabilitar Instrumento <font-awesome-icon :icon="['fas', 'ban']"/></a>
                                                                            <a  v-if="!instrumento.activo" @click="disableInstrumento(instrumento)" class="btn btn--alert-nondisabled">Habilitar Instrumento <font-awesome-icon :icon="['fas', 'circle']"/></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="form-group col-md-6">
                                                                        <label>Nombre*</label>
                                                                        <input type="text" name="" class="form-control" v-model="instrumento.nombre">
                                                                    </div>
                                                                    <div class="form-group col-md-6">
                                                                        <label>Descripción</label>
                                                                        <textarea v-model="instrumento.descripcion" class="form-control"></textarea>
                                                                    </div>
                                                                </div>
                                                                <br>
                                                                <br>
                                                                <div class="btns btns--right">
                                                                    <a @click="updateInstrumento(instrumento);createSubdireccion(subdireccion)" class="btn btn--lightgrey"><font-awesome-icon :icon="['fas', 'indent']" /> Guardar y crear nuevo Instrumento</a>
                                                                    <a @click="updateInstrumento(instrumento)" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'check']" /> Guardar</a>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                        </b-card-body>
                                    <!-- </b-collapse> -->
                                </b-card>
                            </div>
                        </div>
                        <div class="card card--button">
                            <a @click="createSubdireccion()" class="card--button__btn card--button__btn--lightgrey">
                                <font-awesome-icon :icon="['fas', 'plus']" />
                                <span class="card--button__text">Crear Nueva Subdirección</span>
                            </a>
                        </div>
                        <div class="popup" v-if="popUpTrigger" :TogglePopup = " () => TogglePopup()">
                            <div class="popup-inner">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label>Nombre*</label>
                                        <input type="text" name="" class="form-control" v-model="data_instrumento.nombre">
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>Descripción</label>
                                        <textarea v-model="data_instrumento.descripcion" class="form-control"></textarea>
                                    </div>
                                </div>
                                <p>
                                <p>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <b-button variant="danger" class ="popup-close" @click="cancelarCrearInstrumento()">Cancelar</b-button>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <b-button variant="primary" class ="popup-close" @click="createInstrumentoInfo(data_instrumento)">Guardar</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import resource from 'resource-axios'
import router from '../router/index.js'
import Breadcrumb from '@/components/Breadcrumb.vue'

const Agencia = resource('/api/api/crear_agencia', axios)
// const Subdireccion = resource('/api/api/crear_subdireccion', axios)
// const Instrumento = resource('/api/api/crear_instrumento', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'CrearAgencia',
    components: {
        Breadcrumb
    },
    data () {
        return {
            agencia: null,
            subdireccion: null,
            routeParams: this.$route.params.id,
            formAgencia: {},
            subdirecciones: [],
            editar: false,
            show: false,
            errorNombre: '',
            isSuperAdmin: false,
            popUpTrigger: false,
            data_instrumento: { subdireccion: null, nombre: '', descripcion: '' },
            usuario: null
        }
    },
    methods: {
        getData () {
            if (this.routeParams) {
                this.show = true
                Agencia.query({ id: this.routeParams }).then((res) => {
                    this.show = false
                    this.agencia = res.data.items[0]
                })
                    .catch((error) => {
                        console.log(error)
                        this.show = false
                    })
            }
        },
        createAgencia () {
            this.show = true
            axios({
                method: 'post',
                url: '/api/api/crear_agencia/',
                data: this.formAgencia
            })
                .then((response) => {
                    this.show = false
                    router.push({ name: 'CrearSubdirecciones', params: { id: response.data.id } })
                    location.reload()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Agencia creada exitosamente'
                    })
                })
                .catch((error) => {
                    this.show = false
                    this.errorNombre = error.response.data.nombre
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Agencia'
                    })
                })
        },
        updateAgencia (agencia) {
            this.show = true
            axios({
                method: 'put',
                url: '/api/api/crear_agencia/' + agencia.id + '/',
                data: agencia
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.editar = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Agencia actualizada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar la Agencia'
                    })
                    console.log(response)
                })
        },
        disableAgencia (agencia) {
            var title = 'Vas a inhabilitar la agencia y sus asociados, ¿estás seguro?'
            var text = 'Las personas asociadas a esta agencia y asociados no podrán visualizarlo más.'
            if (!agencia.activo) {
                title = 'Vas a habilitar la agencia y sus asociados, ¿estás seguro?'
                text = 'Las personas asociadas a esta agencia y asociados podrán visualizarla.'
            }
            this.$swal({
                title: title,
                text: text,
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    agencia.activo = !agencia.activo
                    this.updateAgencia(agencia)
                }
            })
        },
        createSubdireccion () {
            this.show = true
            axios({
                method: 'post',
                url: '/api/api/crear_subdireccion/',
                data: { agencia: this.routeParams }
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Subdirección creada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Subdirección'
                    })
                })
        },
        updateSubdireccion (subdireccion) {
            this.show = true
            axios({
                method: 'put',
                url: '/api/api/crear_subdireccion/' + subdireccion.id + '/',
                data: subdireccion
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Subdirección guardada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar la Subdirección'
                    })
                    console.log(response)
                })
        },
        disableSubdireccion (subdireccion) {
            var title = 'Vas a inhabilitar la subdireccion y sus asociados, ¿estás seguro?'
            var text = 'Las personas asociadas a esta subdireccion y asociados no podrán visualizarlo más.'
            if (!subdireccion.activo) {
                title = 'Vas a habilitar la subdireccion y sus asociados, ¿estás seguro?'
                text = 'Las personas asociadas a esta subdireccion y asociados podrán visualizarla.'
            }
            this.$swal({
                title: title,
                text: text,
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    subdireccion.activo = !subdireccion.activo
                    this.updateSubdireccion(subdireccion)
                }
            })
        },
        createInstrumento (subdireccion) {
            this.data_instrumento.subdireccion = subdireccion.id
            this.popUpTrigger = true
        },
        cancelarCrearInstrumento () {
            this.popUpTrigger = false
        },
        createInstrumentoInfo (instrumento) {
            this.show = true
            axios({
                method: 'post',
                url: '/api/api/crear_instrumento/',
                data: instrumento
                /* data: { subdireccion: subdireccion.id } */
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Instrumento creada exitosamente'
                    })
                    this.popUpTrigger = false
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear la Instrumento'
                    })
                })
        },
        updateInstrumento (instrumento) {
            this.show = true
            axios({
                method: 'put',
                url: '/api/api/crear_instrumento/' + instrumento.id + '/',
                data: instrumento
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Instrumento guardado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar la Instrumento'
                    })
                    console.log(response)
                })
        },
        disableInstrumento (instrumento) {
            var title = 'Vas a inhabilitar el instrumento, ¿estás seguro?'
            var text = 'Las personas asociadas a este instrumento no podrán visualizarlo más.'
            if (!instrumento.activo) {
                title = 'Vas a habilitar el instrumento , ¿estás seguro?'
                text = 'Las personas asociadas a este instrumento podrán visualizarlo.'
            }
            this.$swal({
                title: title,
                text: text,
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    instrumento.activo = !instrumento.activo
                    this.updateInstrumento(instrumento)
                }
            })
        },
        deleteAlert (agencia) {
            this.$swal({
                title: 'Vas a eliminar la Agencia "' + agencia.nombre + '"',
                text: '¿Estás seguro? Se perderán todos los datos',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteAgencia(agencia)
                }
            })
        },
        deleteAgencia (agencia) {
            this.show = true
            axios({
                method: 'delete',
                url: '/api/api/crear_agencia/' + agencia.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$router.push({ name: 'ListarAgencias' })
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Agencia eliminada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Agencia'
                    })
                })
        },
        deleteAlertSubdireccion (subdireccion) {
            this.$swal({
                title: 'Vas a eliminar la Subdirección "' + subdireccion.nombre + '"',
                text: '¿Estás seguro? Se perderán todos los datos',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteSubdireccion(subdireccion)
                }
            })
        },
        deleteSubdireccion (subdireccion) {
            this.show = true
            axios({
                method: 'delete',
                url: '/api/api/crear_subdireccion/' + subdireccion.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    // this.$router.push({ name: 'ListarAgencias' })
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Subdirección eliminada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Subdirección'
                    })
                })
        },
        deleteAlertInstrumento (instrumento) {
            this.$swal({
                title: 'Vas a eliminar el Instrumento "' + instrumento.nombre + '"',
                text: '¿Estás seguro? Se perderán todos los datos',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteInstrumento(instrumento)
                }
            })
        },
        deleteInstrumento (instrumento) {
            this.show = true
            axios({
                method: 'delete',
                url: '/api/api/crear_instrumento/' + instrumento.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    // this.$router.push({ name: 'ListarAgencias' })
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Instrumento eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Instrumento'
                    })
                })
        }
    },
    mounted () {
        this.getData()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.is_superuser || this.usuario.is_staff) {
                this.isSuperAdmin = true
            }
        })
            .catch((error) => {
                console.log(error)
                this.show = false
            })
    }
}
</script>

<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb v-if="resultados.length > 0" :nombreActual="'Monitoreo Productos y Resultados: ' + resultados[0].indicador__nombre + ' - ' + resultados[0].instrumento__nombre" nombreAnterior="Monitoreo Instrumento" :urlAnterior="/monitoreoinstrumento/ + monitoreoId + '/' + instrumentoId + '/' + anio"></Breadcrumb>
        <b-modal id="modal-cargar-planillas" size="lg" title="Documentos de Respaldo">
            <div class="row">
                <div class="form-group col-md-10" v-if="selectedResultado">
                    <label v-if="isActive(selectedResultado)">Documentos*</label>
                    <b-form-file v-if="isActive(selectedResultado)" multiple :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los documentos" placeholder="No hay documentos seleccionados" ref="files">
                       <template slot="file-name" slot-scope="{ names }">
                             <b-badge variant="light">{{ names[0] }}</b-badge>
                             <b-badge v-if="names && names.length > 1" variant="light" class="ml-1">
                               + {{ names.length - 1 }}
                             </b-badge>
                       </template>
                    </b-form-file>
                    <div class="row" v-if="selectedResultado.planillarespuestas.length > 0">
                        <div class="col-md-12">
                            <h2>Documentos disponibles</h2>
                            <ul class="files-list">
                                <li v-for="documento in selectedResultado.planillarespuestas" v-bind:key="documento.id">
                                    <a  class="file-name" :href="baseUrl + documento.documento__url" target="_blank" donwload>
                                        <font-awesome-icon :icon="['fas', 'file']"/>
                                        <span class="file-name__text">
                                            {{ documento.nombre }}
                                        </span>
                                    </a>
                                    <a @click="alertaPlanillaInhabilitar(documento);" class="ban-file" v-if="((isAdmin || !['Validada', 'Bloqueada'].includes(selectedResultado.monitoreo__estado)) && documento.existe)">
                                        <font-awesome-icon :icon="['fas', 'ban']" />
                                    </a>
                                    <a @click="alertaPlanilla(documento);" v-if="isAdmin" class="delete-file">
                                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModal()">
                    Cancelar
                </b-button>
                <b-button @click="uploadFile(selectedResultado.id)" class="btn btn-primary" :disabled="files.length===0" v-if="selectedResultado && isActive(selectedResultado)">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></b-button>
            </template>
        </b-modal>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section__container">
                            <b-card no-body>
                                <b-tabs card>
                                    <span v-if="anios && anios.length > 0">
                                        <b-tab v-for="anio in anios" v-bind:key="anio.id" v-model="selectedAnio" :title="anio.anio.toString()" active @click="selectedAnio=anio.anio">
                                            <div class="card card--section" v-if="resultados && resultados.length > 0">
                                                <div class="card-header">
                                                    <div>
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <div class="col-md-10">
                                                                    <h2 class="card-title">{{resultados[0].monitoreo__nombre | uppercase}} <span class="badge" v-bind:class="{ 'bg-en-proceso': resultados[0].monitoreo__estado === 'En Proceso', 'bg-por-validar': resultados[0].monitoreo__estado ==='Por Validar', 'bg-validada': resultados[0].monitoreo__estado === 'Validada', 'bg-bloqueada': resultados[0].monitoreo__estado === 'Bloqueada'}">{{resultados[0].monitoreo__estado}}</span></h2>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <div class="float-right" v-if="resultados.length > 0 && isAdmin">
                                                                        <a class="btn btn-danger btn-sm" @click="deleteAlert(resultados, resultados[0].anio)"><font-awesome-icon :icon="['fas', 'trash-alt']" /> Eliminar</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <h3 class="card-subtitle">{{resultados[0].instrumento__nombre}}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <strong>Nombre de Indicador:</strong>
                                                                            <p>{{resultados[0].indicador__nombre}}</p>
                                                                            <br>
                                                                            <strong>Tipo de Indicador:</strong>
                                                                            <br>
                                                                            <p>{{resultados[0].indicador__tipo_indicador}}</p>
                                                                            <br>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <strong>Unidad de Medida:</strong>
                                                                            <p class="">{{resultados[0].unidad_medida__nombre}}</p>
                                                                            <strong>Fórmula:</strong>
                                                                            <p class="">{{resultados[0].unidad_medida__formula}}</p>
                                                                            <strong>Definición de Variables:</strong> <br>
                                                                            <span v-for="variable in resultados[0].indicador__variables" v-bind:key="variable.variable"><strong>{{variable.variable}}: </strong>{{variable.valor}} <br>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                <!--                                     <ModalComentarios :monitoreoInstrumento="monitoreo.id" :instrumento="monitoreo.instrumento" titulo="Monitoreo Instrumento" :modal="'monitoreoinstrumento-' + monitoreo.id"></ModalComentarios> -->
                                                </div>
                                                <div>
                                                </div>
                                                <div class="card-body">
                                                    <h2 class="card-title">Resultados para el año {{selectedAnio}}</h2>
                                                    <div class="panel-body indicadores__box" style="overflow-x: auto;">
                                                        <div class="row titulo-columnas">
                                                            <div class="col-md-3" align="center" v-if="resultados[0].indicador__periodicidad !== 12">
                                                                <span><strong>Mes</strong></span>
                                                            </div>
                                                            <div :class="{ 'col-md-3': resultados[0].indicador__periodicidad !== 12, 'col-md-4': resultados[0].indicador__periodicidad === 12 }" align="center">
                                                                <span><strong>Variables</strong></span>
                                                            </div>
                                                            <div :class="{ 'col-md-3': resultados[0].indicador__periodicidad !== 12, 'col-md-4': resultados[0].indicador__periodicidad === 12 }" align="center">
                                                                <span><strong>Resultado</strong></span>
                                                            </div>
                                                            <div :class="{ 'col-md-3': resultados[0].indicador__periodicidad !== 12, 'col-md-4': resultados[0].indicador__periodicidad === 12 }" align="center">
                                                                <span><strong>Acciones</strong></span>
                                                            </div>
                                                        </div>
                                                        <div class="row caja-mes" v-for="(resultado, index) in resultados" v-bind:key="resultado.id" :class="{'primera-caja-mes': index + 1 == mes_actual }">
                                                            <div class="col-md-3" v-if="resultado.indicador__periodicidad !== 12">
                                                                <p><strong>{{resultado.get_mes_nombre}}</strong></p>
                                                            </div>
                                                            <div :class="{ 'col-md-3': resultado.indicador__periodicidad !== 12, 'col-md-4': resultado.indicador__periodicidad === 12 }">
                                                                <table class="table table-hover" style="margin-bottom: 0;">
                                                                    <tbody>
                                                                        <tr style="border:0px;" v-for="variable in resultado.valor_variables" v-bind:key="variable.variable">
                                                                            <td style="border:0px;" align="center">
                                                                                <label><strong>{{variable.variable}}</strong></label>
                                                                                <input style="width: 75%" type="number" class="form-control" value="" min="0" step="1" required="required" v-model="variable.valor" :disabled="!isActive(resultado)">
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                    <a @click="updateResultado(resultado)" v-if="isActive(resultado)" class="btn btn-primary btn--cumplimiento" style="width: 145px;">
                                                                    <font-awesome-icon :icon="['fas', 'save']" /></a>
                                                            </div>
                                                            <div :class="{ 'col-md-3': resultado.indicador__periodicidad !== 12, 'col-md-4': resultado.indicador__periodicidad === 12 }" align="center">
                                                                <strong>{{resultado.get_calculo}}</strong>
                                                            </div>
                                                            <div :class="{ 'col-md-3': resultado.indicador__periodicidad !== 12, 'col-md-4': resultado.indicador__periodicidad === 12 }" align="left">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <ModalComentarios :instrumento="instrumentoId" titulo="Monitoreo de Productos y Resultados" :modal="'resultado-' + resultado.id" :indicador="resultado.indicador" :resultado="resultado.id" :monitoreoInstrumento="resultado.monitoreo_instrumento__id"></ModalComentarios>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <a @click="openModal(resultado)" class="btn btn-primary btn-sm pull-right">
                                                                            <font-awesome-icon :icon="['fas', 'file']" />
                                                                            <span class=""> Documentos</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" v-model="resultado.validado_agencia" @click="resultado.validado_agencia=!resultado.validado_agencia;updateResultado(resultado)" :disabled="!isAdminAgencia && !isActive(resultado)" />
                                                                            <label class="form-check-label">
                                                                            ¿Validado por Encargado Agencia / Encargado Subdirección(Gerencia)?
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" v-model="resultado.validado_administrador" @click="resultado.validado_administrador=!resultado.validado_administrador; updateResultado(resultado)" :disabled="!isAdmin" />
                                                                            <label class="form-check-label">
                                                                            ¿Validado por el administrador?
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" v-model="resultado.activo" @click="resultado.activo=!resultado.activo; updateResultado(resultado)" :disabled="!isAdmin" />
                                                                            <label class="form-check-label">
                                                                            ¿Activa?
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                    </span>
                                    <b-tab v-if="isAdmin">
                                        <template #title>
                                            Agregar Nuevo Año <font-awesome-icon :icon="['fas', 'plus-circle']" />
                                        </template>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label>
                                                    Ingresa el año para el nuevo set de resultados:
                                                </label>
                                                <v-select :options="years" v-model="newAnio" label="year" placeholder="Seleccionar"></v-select>
                                            </div>
                                            <div class="col-md-6" v-if="newAnio">
                                                <a @click="createResultado(newAnio)" class="btn btn-primary">
                                                <font-awesome-icon :icon="['fas', 'plus']" />
                                                Agregar Resultados para el año {{newAnio}}</a>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import ModalComentarios from '@/components/ModalComentarios.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import moment from 'moment'

const Resultados = resource('/monitoreo/api/editar_resultados', axios)
const Anio = resource('/monitoreo/api/ver_anios_resultados', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'MonitoreoDesempeno',
    components: {
        ModalComentarios,
        Breadcrumb
    },
    data () {
        return {
            resultados: [],
            anios: [],
            selectedAnio: parseInt(this.$route.params.anio),
            newAnio: null,
            mes_actual: moment().month(),
            monitoreoId: this.$route.params.monitoreoId,
            instrumentoId: this.$route.params.instrumentoId,
            indicadorId: this.$route.params.indicadorId,
            anio: this.$route.params.anio,
            monitoreoInstrumentoId: this.$route.params.monitoreoInstrumentoId,
            usuario: {},
            isAdmin: false,
            isAdminAgencia: false,
            files: [],
            acceptedFiles: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/x-rar-compressed,application/zip,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg',
            selectedResultado: null,
            baseUrl: process.env.VUE_APP_BASEURL,
            show: true
        }
    },
    methods: {
        getData () {
            Resultados.query({ indicador: this.indicadorId, anio: this.selectedAnio, monitoreo_instrumento: this.monitoreoInstrumentoId }).then((res) => {
                this.resultados = res.data
                this.show = false
            }, (error) => {
                console.log(error)
                this.show = false
            })
        },
        getAnio () {
            Anio.query({ indicador: this.indicadorId, monitoreo_instrumento: this.monitoreoInstrumentoId }).then((res) => {
                this.anios = res.data
                this.selectedAnio = this.anios[0].anio
                this.getData()
            }, (error) => {
                console.log(error)
            })
        },
        updateResultado (resultado) {
            this.show = true
            axios({
                method: 'put',
                url: '/monitoreo/api/editar_resultados/' + resultado.id + '/',
                data: resultado
            })
                .then((response) => {
                    this.getData()
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Resultado guardado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al guardar el Resultado'
                    })
                    console.log(response)
                })
        },
        createResultado (anio, indicador) {
            var formResultado = {}
            formResultado.anio = anio
            formResultado.indicador = this.indicadorId
            formResultado.monitoreo_instrumento = this.monitoreoInstrumentoId
            this.show = true
            axios({
                method: 'put',
                url: '/monitoreo/api/crear_resultados_indicador_anio/' + this.indicadorId + '/crear/',
                data: formResultado
            })
                .then((response) => {
                    this.show = false
                    this.getAnio()
                    this.newAnio = null
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Resultado creado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el Resultado'
                    })
                    console.log(response)
                })
        },
        openModal (resultado) {
            this.selectedResultado = resultado
            this.$root.$emit('bv::show::modal', 'modal-cargar-planillas')
        },
        closeModal () {
            this.selectedResultado = null
            this.$root.$emit('bv::hide::modal', 'modal-cargar-planillas')
        },
        uploadFile (resultado) {
            var formData = new FormData()
            for (var i = 0; i < this.files.length; i++) {
                var file = this.files[i]
                formData.append('documento[' + i + ']', file)
            }
            formData.append('monitoreoinstrumento', this.monitoreoInstrumentoId)
            formData.append('resultados', resultado)
            this.show = true
            axios.post('/monitoreo/api/cargar_planilla_respuesta/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.closeModal()
                    this.files = []
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Planillas cargados exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al cargar los Planillas'
                    })
                })
        },
        deleteFile (planilla) {
            this.show = true
            axios({
                method: 'delete',
                url: '/monitoreo/api/cargar_planilla_respuesta/' + planilla.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Planilla eliminada exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar la Planilla'
                    })
                })
        },
        alertaPlanilla (planilla) {
            this.$swal({
                title: 'Vas a eliminar esta planilla, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFile(planilla)
                }
            })
        },
        alertaPlanillaInhabilitar (planilla) {
            this.$swal({
                title: 'Vas a inhabilitar esta planilla, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.inhabilitarPlanilla(planilla)
                }
            })
        },
        inhabilitarPlanilla (planilla) {
            axios.put('/monitoreo/api/cargar_planilla_respuesta/' + planilla.id + '/inhabilitar_planilla/').then((res) => {
                if (res.status === 200) {
                    this.getData()
                }
            })
                .catch((res) => {
                    console.log(res)
                })
        },
        isActive (resultado) {
            var validadoAdmin = false
            var activo = false
            var estado = false
            if (!resultado.validado_administrador) validadoAdmin = true
            if (resultado.activo) activo = true
            if (!['Validada', 'Bloqueada'].includes(resultado.monitoreo__estado)) estado = true
            if (validadoAdmin && activo && estado) return true
            else return false
        },
        deleteAlert (resultados, anio) {
            this.$swal({
                title: 'Vas a eliminar los resultados asociados al año ' + anio,
                text: '¿Estás seguro? Se perderá toda la información asociada',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteResultados(resultados)
                }
            })
        },
        deleteResultados (resultados) {
            var elementos = []
            resultados.forEach(elemento => {
                var elementoDict = {}
                elementoDict = {
                    method: 'delete',
                    url: '/monitoreo/api/editar_resultados/' + elemento.id + '/'
                }
                elementos.push(axios(elementoDict))
            })
            if (elementos.length > 0) {
                this.show = true
                axios.all(elementos)
                    .then((response) => {
                        this.show = false
                        this.getData()
                        this.getAnio()
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: 'Resultados eliminados exitosamente'
                        })
                    })
                    .catch((response) => {
                        this.show = false
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al eliminar el Resultados'
                        })
                        console.log(response)
                    })
            }
        }
    },
    mounted () {
        this.getAnio()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            }
            if (this.usuario.tipo_usuario === 'Encargado Agencia' || this.usuario.tipo_usuario === 'Encargado Subdirección' || this.usuario.is_superuser) {
                this.isAdminAgencia = true
            }
        })
        this.$watch('selectedAnio', (newLocale, oldLocale) => {
            if (newLocale !== oldLocale) {
                this.getData()
            }
        }, { immediate: true })
    },
    computed: {
        years () {
            const year = new Date().getFullYear()
            var temp = Array.from({ length: year - 2005 }, (value, index) => 2011 + index)
            return temp
        }
    }
}
</script>

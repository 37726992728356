<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Fichas de Evaluación"></Breadcrumb>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="card card--section">
                            <div class="card-header">
                                <h2 class="card-title d-inline-block">Fichas de Evaluación</h2>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        Filtrar por:
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3" v-if="!isAdmin">
                                        <v-select :disabled="true" :options="listAgencia" v-model="formSearch.agencia" label="nombre" :reduce="nombre => nombre.id" placeholder="Agencia" class="mb-2"></v-select>
                                    </div>
                                    <div class="col-md-3" v-if="isAdmin">
                                        <v-select :options="listAgencia" v-model="formSearch.agencia" label="nombre" :reduce="nombre => nombre.id" placeholder="Agencia" class="mb-2"></v-select>
                                    </div>
                                    <div class="col-md-3">
                                        <v-select :options="listSubdireccion" v-model="formSearch.subdireccion" label="nombre" :reduce="nombre => nombre.id" placeholder="Subdirección / Gerencia" class="mb-2">
                                            <span slot="no-options">Seleccione una Agencia Primero.</span>
                                        </v-select>
                                    </div>
                                    <div class="col-md-3">
                                        <v-select :options="listInstrumento" v-model="formSearch.instrumento" label="nombre" :reduce="nombre => nombre.id" placeholder="Instrumento" class="mb-2">
                                            <span slot="no-options">Seleccione una Subdirección / Gerencia Primero.</span>
                                        </v-select>
                                    </div>
                                    <div class="col-md-3" v-if="!isAdmin">
                                        <v-select :disabled="true" :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año" class="mb-2"></v-select>
                                    </div>
                                    <div class="col-md-3" v-if="isAdmin">
                                        <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año" class="mb-2"></v-select>
                                    </div>
                                </div>
                                <br>
                                <div class="alert alert-info" role="alert" v-if="fichas.length === 0">
                                        No hay información disponible
                                    </div>
                                <div class="table-responsive">
                                    <table class="table table-stripped table-hover table-bordered" id="tabla-fichas" v-if="fichas.length > 0">
                                        <thead>
                                            <tr>
                                                <td v-if="isAdmin"></td>
                                                <td>Nombre Ficha</td>
                                                <td>Año</td>
                                                <td>Tipo Ficha</td>
                                                <td>Última Actualización</td>
                                                <td>Usuario/a</td>
                                                <td>Instrumento Asociado</td>
                                                <td>Estado</td>
                                                <td>Acción</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- <tr v-if="fichas.length === 0">
                                                <small class="badge bg-alert">No existen Fichas</small>
                                            </tr> -->
                                            <tr v-for="ficha in resultQuery" v-bind:key="ficha.id">
                                                <td v-if="isAdmin"><font-awesome-icon v-if="!ficha.activo" :icon="['fas', 'ban']" style="color: red;"/><font-awesome-icon v-if="ficha.activo" :icon="['fas', 'circle']" style="color: #63C894;"/></td>
                                                <td>{{ficha.ficha__nombre}}</td>
                                                <td>{{ficha.anio}}</td>
                                                <td>{{ficha.ficha__tipo_ficha}}</td>
                                                <td>{{ficha.fecha_actualizacion | dateTimeFormat}}</td>
                                                <td>{{ficha.persona__nombre}}</td>
                                                <td>{{ficha.instrumento__nombre}}</td>
                                                <td><span class="badge" v-bind:class="{ 'bg-en-proceso': ficha.estado === 'En Proceso', 'bg-por-validar': ficha.estado ==='Por Validar', 'bg-validada': ficha.estado === 'Validada', 'bg-bloqueada': ficha.estado === 'Bloqueada'}">{{ficha.estado}}</span></td>
                                                <td>
                                                    <div class="btns mt-0">
                                                        <router-link :to="'/ficha/' + ficha.ficha + '/' + ficha.instrumento + '/' + ficha.anio" class="btn btn-primary btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /> Editar Ficha</router-link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="tabla-fichas"
                                ></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'

// const Ficha = resource('/ficha_instrumentos/api/listar_fichas_instrumentos', axios)
const Ficha = resource('/ficha_instrumentos/api/nopag_listar_fichas_instrumentos', axios)
const Anio = resource('/ficha_instrumentos/api/anios_ficha_instrumento', axios)
const ListarAgencia = resource('/api/api/listar_agencia', axios)
const ListarSubdireccion = resource('/api/api/listar_subdireccion', axios)
const ListarInstrumento = resource('/api/api/listar_instrumento', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'ListarFichasEvaluacion',
    components: {
        Breadcrumb
    },
    data () {
        return {
            fichas: [],
            currentPage: 1,
            perPage: 15,
            rows: null,
            page: null,
            anios: [],
            formSearch: { agencia: null, subdireccion: null, instrumento: null, anio: null },
            listAgencia: [],
            listSubdireccion: [],
            listInstrumento: [],
            isAdmin: false,
            show: true,
            original_length: null
        }
    },
    methods: {
        getAnio () {
            Anio.query({ ficha__tipo_ficha: 'Evaluación' }).then((res) => {
                this.anios = res.data
                this.formSearch.anio = 2022
            })
        },
        getListAgencia () {
            ListarAgencia.query({ }).then((res) => {
                this.listAgencia = res.data
            })
        },
        getListSubdireccion () {
            ListarSubdireccion.query({ agencia: this.formSearch.agencia }).then((res) => {
                this.listSubdireccion = res.data
            })
        },
        getListInstrumento () {
            ListarInstrumento.query({ subdireccion__agencia: this.formSearch.agencia, subdireccion: this.formSearch.subdireccion }).then((res) => {
                this.listInstrumento = res.data
            })
        },
        getData () {
            // Ficha.query({ page: this.currentPage, per_page: 10, ficha__tipo_ficha: 'Evaluación', instrumento: this.formSearch.instrumento, instrumento__subdireccion: this.formSearch.subdireccion, instrumento__subdireccion__agencia: this.formSearch.agencia, anio: this.formSearch.anio }).then((res) => {
            Ficha.query({ ficha__tipo_ficha: 'Evaluación', instrumento: this.formSearch.instrumento, instrumento__subdireccion: this.formSearch.subdireccion, instrumento__subdireccion__agencia: this.formSearch.agencia, anio: this.formSearch.anio }).then((res) => {
                // this.fichas = res.data.items
                this.fichas = res.data
                // this.perPage = res.data.paginate.per_page
                // this.rows = res.data.paginate.count
                this.rows = res.data.length
                this.original_length = res.data.length
                this.show = false
            }, function (error) {
                this.show = false
                console.log(error)
            })
        }
    },
    mounted () {
        this.getListAgencia()
        this.getAnio()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            } else {
                this.listAgencia.forEach((ag) => {
                    if (ag.nombre === this.usuario.agencia__nombre) {
                        this.formSearch.agencia = ag.id
                    }
                })
                this.anios = [this.anios.at(-1)]
            }
            // this.getData()
        })
    },
    computed: {
        resultQuery () {
            const startIndex = this.perPage * (this.currentPage - 1)
            const endIndex = startIndex + this.perPage
            return this.fichas.slice(startIndex, endIndex)
        }
    },
    watch: {
        'formSearch.anio': {
            deep: true,
            handler: function (newVal) {
                this.getData()
            }
        },
        'formSearch.agencia': {
            deep: true,
            handler: function (newVal) {
                if (newVal !== null) {
                    this.formSearch.subdireccion = null
                    this.getListSubdireccion()
                    this.getListInstrumento()
                } else {
                    this.listSubdireccion = []
                    this.listInstrumento = []
                    this.formSearch.subdireccion = null
                    this.formSearch.instrumento = null
                }
                this.getData()
            }
        },
        'formSearch.subdireccion': {
            deep: true,
            handler: function (newVal) {
                if (newVal !== null) {
                    this.formSearch.instrumento = null
                    this.getListInstrumento()
                } else {
                    this.listInstrumento = []
                    this.formSearch.instrumento = null
                }
                this.getData()
            }
        },
        'formSearch.instrumento': {
            deep: true,
            handler: function (newVal) {
                this.getData()
            }
        }
    }
}
</script>
